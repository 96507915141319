import { apiSlice } from "../../baseApi";

export const extendedVehicleModelApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addVehicleModel: builder.mutation({
      query: (body) => ({
        url: "/vehicle-model",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["VehicleModel"],
    }),

    getVehicleModel: builder.query({
      query: () => ({
        url: "/vehicle-model",
      }),
      providesTags: (result, query, args) => [{ type: "VehicleModel" }],
    }),

    updateVehicleModel: builder.mutation({
      query: (data) => ({
        url: "/vehicle-model",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["VehicleModel"],
    }),
    getVehicleModelById: builder.query({
      query: (id) => `/vehicle-model/${id}`,
      providesTags: ["VehicleModel"],
    }),

    deleteVehicleModel: builder.mutation({
      query: (id) => ({
        url: `/vehicle-model/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VehicleModel"],
    }),
  }),
});

export const {
  useAddVehicleModelMutation,
  useDeleteVehicleModelMutation,
  useGetVehicleModelByIdQuery,
  useGetVehicleModelQuery,
  useUpdateVehicleModelMutation,
} = extendedVehicleModelApi;
