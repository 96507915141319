import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { AttendantDashboard } from "../../../../components/dashboard-views/attendantDashboard";
import { AdminDashboard } from "../../../../components/dashboard-views/adminDashboard";
import { SuperAdminDashboard } from "../../../../components/dashboard-views/superAdminDashboard";
import { ChargingPointAdminDashboard } from "../../../../components/dashboard-views/chargingPointAdminDashboard";

const NavbarScrollbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPages, setIsOpenPages] = useState(false);
  const [isOpenBooking, setIsOpenBooking] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const navigate = useNavigate();

  const [userRole, setUserRole] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const encryptedUser = localStorage.getItem("user");

    const decryptedUserJSON = atob(encryptedUser);
    const decryptedUser = JSON.parse(decryptedUserJSON);
    setUser(decryptedUser);
  }, []);

  const handleMouseEnterPages = () => {
    setIsOpenPages(true);
  };

  const handleMouseLeavePages = () => {
    setIsOpenPages(false);
  };

  const handleMouseEnterBooking = () => {
    setIsOpenBooking(true);
  };

  const handleMouseLeaveBooking = () => {
    setIsOpenBooking(false);
  };

  const handleMouseEnterSettings = () => {
    setIsOpenSettings(true);
  };

  const handleMouseLeaveSettings = () => {
    setIsOpenSettings(false);
  };
  return (
    <div id="scrollbar">
      <div className="container-fluid">
        <div id="two-column-menu"></div>

        {/* ATTENDANT ACCOUNT */}
        {!user?.role && navigate("/login")}
        {user && user?.role === "super-admin" && (
          <SuperAdminDashboard
            isOpenPages={isOpenPages}
            isOpenBooking={isOpenBooking}
            isOpenSettings={isOpenSettings}
            handleMouseEnterPages={handleMouseEnterPages}
            handleMouseLeavePages={handleMouseLeavePages}
            handleMouseEnterBooking={handleMouseEnterBooking}
            handleMouseLeaveBooking={handleMouseLeaveBooking}
            handleMouseEnterSettings={handleMouseEnterSettings}
            handleMouseLeaveSettings={handleMouseLeaveSettings}
          />
        )}

        {user && user?.role === "charging-point-admin" && (
          <ChargingPointAdminDashboard
            isOpenPages={isOpenPages}
            isOpenBooking={isOpenBooking}
            isOpenSettings={isOpenSettings}
            handleMouseEnterPages={handleMouseEnterPages}
            handleMouseLeavePages={handleMouseLeavePages}
            handleMouseEnterBooking={handleMouseEnterBooking}
            handleMouseLeaveBooking={handleMouseLeaveBooking}
            handleMouseEnterSettings={handleMouseEnterSettings}
            handleMouseLeaveSettings={handleMouseLeaveSettings}
          />
        )}
        {user && user?.role === "admin" && (
          <AdminDashboard
            isOpenPages={isOpenPages}
            isOpenBooking={isOpenBooking}
            isOpenSettings={isOpenSettings}
            handleMouseEnterPages={handleMouseEnterPages}
            handleMouseLeavePages={handleMouseLeavePages}
            handleMouseEnterBooking={handleMouseEnterBooking}
            handleMouseLeaveBooking={handleMouseLeaveBooking}
            handleMouseEnterSettings={handleMouseEnterSettings}
            handleMouseLeaveSettings={handleMouseLeaveSettings}
          />
        )}

        {user && user?.role === "attendant" && (
          <AttendantDashboard
            isOpenPages={isOpenPages}
            isOpenBooking={isOpenBooking}
            isOpenSettings={isOpenSettings}
            handleMouseEnterPages={handleMouseEnterPages}
            handleMouseLeavePages={handleMouseLeavePages}
            handleMouseEnterBooking={handleMouseEnterBooking}
            handleMouseLeaveBooking={handleMouseLeaveBooking}
            handleMouseEnterSettings={handleMouseEnterSettings}
            handleMouseLeaveSettings={handleMouseLeaveSettings}
          />
        )}
      </div>
    </div>
  );
};

export default NavbarScrollbar;
