import React from "react";
import { Link } from "react-router-dom";
import { useGetBookingByChargingPointQuery } from "../../features/api/bookings/bookingSlotAPI";

export const ChargingPointAdminDashboardContent = () => {
  const { data: bookingList, loading: bookingListLoading } =
    useGetBookingByChargingPointQuery();

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Users
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-success fs-14 mb-0">
                    <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                    +16.24 %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    $
                    <span className="counter-value" data-target="559.25">
                      0
                    </span>
                    k
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    View net earnings
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-success rounded fs-3">
                    <i className="bx bx-dollar-circle text-success"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Charging Stations
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-danger fs-14 mb-0">
                    <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
                    -3.57 %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="36894">
                      0
                    </span>
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    View all orders
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-info rounded fs-3">
                    <i className="bx bx-shopping-bag text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Bookings
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-success fs-14 mb-0">
                    <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                    +29.08 %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="183.35">
                      0
                    </span>
                    M
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    See details
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-warning rounded fs-3">
                    <i className="bx bx-user-circle text-warning"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Admin Users
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-muted fs-14 mb-0">+0.00 %</h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    $
                    <span className="counter-value" data-target="165.89">
                      0
                    </span>
                    k
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    Withdraw money
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-primary rounded fs-3">
                    <i className="bx bx-wallet text-primary"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Attendants
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-success fs-14 mb-0">
                    <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                    +16.24 %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    $
                    <span className="counter-value" data-target="559.25">
                      0
                    </span>
                    k
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    View net earnings
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-success rounded fs-3">
                    <i className="bx bx-dollar-circle text-success"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Charging Stations
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-danger fs-14 mb-0">
                    <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
                    -3.57 %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="36894">
                      0
                    </span>
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    View all orders
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-info rounded fs-3">
                    <i className="bx bx-shopping-bag text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Bookings
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-success fs-14 mb-0">
                    <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                    +29.08 %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="183.35">
                      0
                    </span>
                    M
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    See details
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-warning rounded fs-3">
                    <i className="bx bx-user-circle text-warning"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Users
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-muted fs-14 mb-0">+0.00 %</h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    $
                    <span className="counter-value" data-target="165.89">
                      0
                    </span>
                    k
                  </h4>
                  <a href="#" className="text-decoration-underline text-muted">
                    Withdraw money
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-primary rounded fs-3">
                    <i className="bx bx-wallet text-primary"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* recent bookings */}

      <div className="col-xl-12">
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Recent Bookings</h4>
            <div className="flex-shrink-0">
              <button type="button" className="btn btn-soft-info btn-sm">
                <i className="ri-file-list-3-line align-middle"></i> Generate
                Report
              </button>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive table-card">
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Charging Point</th>
                    <th scope="col">Connector Point</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Charge Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingList &&
                    bookingList.data.length > 0 &&
                    bookingList.data.map((booking, index) => (
                      <tr key={index}>
                        <td>
                          <a
                            href="apps-ecommerce-order-details.html"
                            className="fw-medium link-primary"
                          >
                            {booking?.booking_id}
                          </a>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-2">
                              <img
                                src="assets/images/users/avatar-1.jpg"
                                alt=""
                                className="avatar-xs rounded-circle"
                              />
                            </div>
                            <div className="flex-grow-1">
                              {booking?.user?.frist_name +
                                " " +
                                booking?.user?.last_name}
                            </div>
                          </div>
                        </td>
                        <td>{booking?.charging_point?.name}</td>
                        <td>{booking?.charging_point?.name}</td>

                        <td>
                          <span className="text-success">
                            {booking?.price?.currency +
                              " " +
                              booking?.price?.value}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              booking?.payment_status === "successful"
                                ? "badge-soft-success"
                                : booking?.payment_status === "declined"
                                ? "badge-soft-danger"
                                : "badge-soft-warning"
                            }`}
                          >
                            {booking?.payment_status}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              booking?.charge_status === "charged"
                                ? "badge-soft-success"
                                : booking?.charge_status === "charging"
                                ? "badge-soft-danger"
                                : "badge-soft-warning"
                            }`}
                          >
                            {booking?.charge_status}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <div className="edit">
                              <Link className="btn btn-sm btn-success edit-item-btn">
                                View
                              </Link>
                            </div>
                            {/* <div className="remove">
                              <button
                                className="btn btn-sm btn-danger remove-item-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteRecordModal"
                              >
                                Delete
                              </button>
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Revenue</h4>
              <div>
                <button type="button" className="btn btn-soft-secondary btn-sm">
                  ALL
                </button>
                <button type="button" className="btn btn-soft-secondary btn-sm">
                  1M
                </button>
                <button type="button" className="btn btn-soft-secondary btn-sm">
                  6M
                </button>
                <button type="button" className="btn btn-soft-primary btn-sm">
                  1Y
                </button>
              </div>
            </div>

            <div className="card-header p-0 border-0 bg-soft-light">
              <div className="row g-0 text-center">
                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target="7585">
                        0
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Orders</p>
                  </div>
                </div>

                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      $
                      <span className="counter-value" data-target="22.89">
                        0
                      </span>
                      k
                    </h5>
                    <p className="text-muted mb-0">Earnings</p>
                  </div>
                </div>

                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target="367">
                        0
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Refunds</p>
                  </div>
                </div>

                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0 border-end-0">
                    <h5 className="mb-1 text-success">
                      <span className="counter-value" data-target="18.92">
                        0
                      </span>
                      %
                    </h5>
                    <p className="text-muted mb-0">Conversation Ratio</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body p-0 pb-2">
              <div className="w-100">
                <div
                  id="customer_impression_charts"
                  data-colors='["--vz-warning", "--vz-primary", "--vz-success"]'
                  className="apex-charts"
                  dir="ltr"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChargingPointAdminDashboardContent;
