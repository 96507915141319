import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../../utils/colors";
import { Col, Modal, Button, Row, Form } from "react-bootstrap";
import Input from "../../../../../components/common/input/input";
import { baseUrl } from "../../../../../utils/config";
import { useGetNotificationQuery } from "../../../../../features/api/notification/notificationApi";

function NotificationData({ users, metadata, currentPage }) {
  const navigate = useNavigate();
  const { data: notificationData, isLoading: notificationLoading } =
    useGetNotificationQuery();

  console.log("notificationData", notificationData);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleCloseDelete = () => setShowDelete(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const pages = [];

  for (let i = 0; i < Math.ceil(metadata.total / 20); i++) {
    pages.push(i + 1);
  }

  return (
    <div id="customerList">
      <div className="row g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            {/* <button
              type="button"
              className="btn btn-success add-btn"
              id="create-btn"
              onClick={handleShow}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Users
            </button> */}
          </div>
        </div>
        <div className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <input
                type="text"
                className="form-control search"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive table-card mt-3 mb-1">
        <table className="table align-middle table-nowrap" id="customerTable">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                  />
                </div>
              </th>
              <th className="sort"></th>
              <th className="sort"> Name</th>
              <th className="sort">Email Address</th>
              <th className="sort">Phone Number</th>
              <th className="sort">Photo</th>
              <th className="sort">Status</th>
              <th className="sort">Actions</th>
            </tr>
          </thead>
          <tbody className="list form-check-all">
            {notificationData && notificationData?.data?.length > 0 ? (
              notificationData?.data?.map((item) => (
                <tr key={item.id}>
                  <th scope="row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk_child"
                        value="option1"
                      />
                    </div>
                  </th>
                  <td>
                    {/* <img src={product.primary_image?.thumbnail?.url} alt="" /> */}
                  </td>
                  <td>{item.firstname}</td>
                  <td>{item.email}</td>
                  <td>"jjjjjjj"</td>
                  <td>"jjjjjjj"</td>
                  <td className="status">
                    {/* {product.isActive ? (
                      <span className="badge badge-soft-success text-uppercase">
                        Active
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        Inactive
                      </span>
                    )} */}
                  </td>
                  <td>
                    {/* <div className="d-flex gap-2">
          <div className="edit">
            <Link
              to={`${product._id}`}
              className="btn btn-sm btn-success edit-item-btn"
            >
              Open
            </Link>
          </div>
          <div className="remove">
            <button
              className="btn btn-sm btn-danger remove-item-btn"
              data-bs-toggle="modal"
              data-bs-target="#deleteRecordModal"
            >
              Delete
            </button>
          </div>
        </div> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No items found.</td>
              </tr>
            )}

            {/* Render notifications */}
            {notifications.map((notification, index) => (
              <tr key={index}>
                <td colSpan="7">{notification}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* --------------------------------------------------------------------------------------------------- ------*/}
        <div
          className="d-flex mx-3"
          style={{ justifyContent: "space-between" }}
        >
          <div
            role="status"
            aria-live="polite"
            class="gridjs-summary"
            title="Page 1 of 2"
          >
            Showing <b>{(currentPage - 1) * 20 + 1}</b> to{" "}
            <b>
              {(currentPage - 1) * 20 + 20 <= metadata.total
                ? (currentPage - 1) * 20 + 20
                : metadata.total}
            </b>{" "}
            of <b>{metadata.total}</b> results
          </div>

          <div className="pagination-wrap hstack gap-2">
            <Link
              className={`page-item pagination-prev ${
                !metadata.prevPage ? "disabled" : ""
              }`}
              to={`${
                metadata.prevPage
                  ? "/dashboard/products?page=" + metadata.prevPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Previous
            </Link>
            <ul className="pagination listjs-pagination mb-0">
              {pages.map((page) => (
                <li class={`${currentPage == page ? "active" : ""}`}>
                  <Link
                    class="page"
                    to={`/dashboard/products?page=${page}`}
                    data-i="1"
                    data-page="8"
                  >
                    {page}
                  </Link>
                </li>
              ))}
            </ul>
            <Link
              className={`page-item pagination-next ${
                !metadata.nextPage ? "disabled" : ""
              }`}
              to={`${
                metadata.nextPage
                  ? "/dashboard/products?page=" + metadata.nextPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Next
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationData;
