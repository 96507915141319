import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../utils/colors";
import { Col, Modal, Button, Row, Form } from "react-bootstrap";
import Input from "../../../../components/common/input/input";
import { useVerifyAdminUserMutation } from "../../../../features/api/admin-user/adminUserApi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { useAddAttendantMutation } from "../../../../features/api/attendant-user/attendantApi";
import { useGetUserPhoneNumberStatusQuery } from "../../../../features/api/users/user";
import { useGetUserEmailStatusQuery } from "../../../../features/api/users/user";
import { useAddAttendantToChargingPointMutation } from "../../../../features/api/charging-point/chargingPointAPI";
import { useGetAttendantByChargingPointByIdQuery } from "../../../../features/api/charging-point/chargingPointAPI";

function ChargingPointAttendantData({ data, metadata, currentPage }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(false);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
  });

  const [saveAttendant] = useAddAttendantToChargingPointMutation();
  const [verifyAdmin] = useVerifyAdminUserMutation();
  const handleCloseDelete = () => setShowDelete(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const pages = [];

  for (let i = 0; i < Math.ceil(metadata.total / 20); i++) {
    pages.push(i + 1);
  }
  const {
    data: phoneNumberCheck,
    error,
    isLoading,
  } = useGetUserPhoneNumberStatusQuery(formData.phone_number);

  const {
    data: emailCheck,
    error: emailCheckError,
    isLoading: emailCheckLoading,
  } = useGetUserEmailStatusQuery(formData.email);

  const {
    data: attendantsByChargingPoint,
    error: attendantsByChargingPointError,
    isLoading: attendantsByChargingPointLoading,
  } = useGetAttendantByChargingPointByIdQuery();

  console.log(
    "useGetAttendantByChargingPointByIdQuery",
    attendantsByChargingPoint
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const verifyEmail = async () => {
    try {
      const { data } = await verifyAdmin({
        email: formData.email,
        code: formData.otp,
      });
      console.log("datasadfad>>>>>", data);
      if (data.success === true) {
        return toast.success("Attendant created successfully");
      } else {
        return toast.error("Error creating Attendant", data);
      }
    } catch (error) {
      console.error("An error occurred while verifying Attendant:", error);
      return toast.error("An error occurred while verifying Attendant");
    } finally {
      setShowSpinner(false);
      handleClose();
    }
  };

  const handleAddStationAttendant = async (e) => {
    try {
      e.preventDefault();
      setShowSpinner(true);

      const data = await saveAttendant({
        first_name: formData.firstname,
        last_name: formData.lastname,
        email: formData.email,
        phone_number: formData.phone_number,
        role: "attendant",
        password: "1234567890",
        isEmailVerified: true,
      });

      if (data?.data?.success === true) {
        // setEmail(true);
        toast.success("Attendant added succesfully");
      }
      // verifyEmail();

      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        otp: "",
        role: "",
      });
    } catch (error) {
      toast.error("An error occured");
    } finally {
      setShowSpinner(false);

      handleClose();
    }
  };

  // const handleShowDropDown = async (e) => {
  //   console.log("ieieiei", showDropDown);
  //   setShowDropDown(true);
  // };
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      checkPhoneNumberExists();
    }, 300);

    return () => clearTimeout(delay);
  }, 100);

  useEffect(() => {
    const delay = setTimeout(() => {
      checkEmailExists();
    }, 300);

    return () => clearTimeout(delay);
  }, 100);

  const checkPhoneNumberExists = async () => {
    try {
      const response = await fetch(
        `/api/user/check-phone-number/${formData.phone_number}`
      );
      const data = await response.json();

      if (response.ok) {
        setPhoneNumberExists(data.exists);
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.error("Failed to fetch:", error.message);
      // Handle error if needed
    }
  };

  const checkEmailExists = async () => {
    try {
      const response = await fetch(`/api/user/check-email/${formData.email}`);
      const data = await response.json();

      console.log("Email check response:", data); // Add this line

      if (response.ok) {
        setEmailExists(data.exists);
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      console.error("Failed to fetch:", error.message);
      // Handle error if needed
    }
  };

  const handleInputChangeDebounced = (e) => {
    handleInputChange(e);
  };

  useEffect(() => {
    if (phoneNumberCheck) {
      setPhoneNumberExists(phoneNumberCheck.exists);
    }
    if (error) {
      console.error("Error checking phone number:", error);
    }
  }, [phoneNumberCheck, error]);

  useEffect(() => {
    if (emailCheck) {
      setEmailExists(emailCheck.exists);
    }
    if (error) {
      console.error("Error checking phone number:", error);
    }
  }, [emailCheck, error]);

  return (
    <div id="customerList">
      <div className="row g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <button
              type="button"
              className="btn btn-primary add-btn"
              id="create-btn"
              //   onClick={() => navigate("/dashboard/products/add")}
              onClick={handleShow}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Station
              Attendant
            </button>
          </div>
        </div>
        <div className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <input
                type="text"
                className="form-control search"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive table-card mt-3 mb-1">
        <table className="table align-middle table-nowrap" id="customerTable">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                  />
                </div>
              </th>
              <th className="sort">S/N</th>
              <th className="sort">Picture</th>
              <th className="sort"> Name</th>
              <th className="sort">Email</th>
              <th className="sort">Phone Number</th>
              <th className="sort">Email Verification</th>
              <th className="sort">Status</th>
              <th className="sort">Actions</th>
            </tr>
          </thead>
          <tbody className="list form-check-all">
            {attendantsByChargingPoint &&
            attendantsByChargingPoint?.data?.length > 0 ? (
              attendantsByChargingPoint?.data.map((item, index) => (
                <tr key={data.id}>
                  <th scope="row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk_child"
                        value="option1"
                      />
                    </div>
                  </th>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={
                        item.picture
                          ? item.picture
                          : "/assets/images/users/user-dummy-img.jpg"
                      }
                      alt="Item"
                      className="rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>{item.firstname + " " + item.lastname}</td>
                  <td>{item.email}</td>

                  <td>{item.phone_number}</td>
                  <td className="status">
                    {item.isDisabled === false ? (
                      <span className="badge badge-soft-success text-uppercase">
                        Verified
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        Inactive
                      </span>
                    )}
                  </td>
                  {/* <td className="status">
                    {item.isDisabled === false ? (
                      <span className="badge badge-soft-success text-uppercase">
                        Active
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        Inactive
                      </span>
                    )}
                  </td> */}
                  <td>
                    <div class="dot"></div>
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      <div className="edit">
                        <Link
                          //   to={`${item._id}`}
                          className="btn btn-sm btn-success edit-item-btn"
                          onClick={() => {
                            setShowDelete(true);
                          }}
                        >
                          Verify Attendant
                        </Link>
                      </div>
                      <div className="remove">
                        <button
                          className="btn btn-sm btn-danger remove-item-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteRecordModal"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No users found.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* --------------------------------------------------------------------------------------------------- ------*/}
        <div
          className="d-flex mx-3"
          style={{ justifyContent: "space-between" }}
        >
          <div
            role="status"
            aria-live="polite"
            class="gridjs-summary"
            title="Page 1 of 2"
          >
            Showing <b>{(currentPage - 1) * 20 + 1}</b> to{" "}
            <b>
              {(currentPage - 1) * 20 + 20 <= metadata.total
                ? (currentPage - 1) * 20 + 20
                : metadata.total}
            </b>{" "}
            of <b>{metadata.total}</b> results
          </div>

          <div className="pagination-wrap hstack gap-2">
            <Link
              className={`page-item pagination-prev ${
                !metadata.prevPage ? "disabled" : ""
              }`}
              to={`${
                metadata.prevPage
                  ? "/dashboard/products?page=" + metadata.prevPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Previous
            </Link>
            <ul className="pagination listjs-pagination mb-0">
              {pages.map((page) => (
                <li class={`${currentPage == page ? "active" : ""}`}>
                  <Link
                    class="page"
                    to={`/dashboard/products?page=${page}`}
                    data-i="1"
                    data-page="8"
                  >
                    {page}
                  </Link>
                </li>
              ))}
            </ul>
            <Link
              className={`page-item pagination-next ${
                !metadata.nextPage ? "disabled" : ""
              }`}
              to={`${
                metadata.nextPage
                  ? "/dashboard/products?page=" + metadata.nextPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Next
            </Link>
          </div>
        </div>
      </div>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Verify  Attendant</Modal.Title>
        </Modal.Header>
        <form action="" onSubmit="">
          <Modal.Body>
            <p>
              You are about to VERIFY <strong>{}</strong>. Do you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button className="btn btn-primary" onClick={handleCloseDelete}>
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button className="btn btn-success" type="submit">
                Yes, Verify
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Station Attendant</Modal.Title>
        </Modal.Header>
        <Form.Group>
          <div style={{ padding: "30px" }}>
            <>
              <Row>
                <Col md="12">
                  <Input
                    name="firstname"
                    label="First Name"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Input
                    name="lastname"
                    label="Last Name"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChangeDebounced}
                    label="Email Address"
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
              </Row>
              {emailExists && (
                <p style={{ color: "red" }}>Email address is already taken</p>
              )}
              <Row>
                <Col md="12">
                  <Input
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChangeDebounced}
                    label="Phone Number"
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
                <div>
                  {phoneNumberExists && (
                    <p style={{ color: "red" }}>
                      Phone number is already taken
                    </p>
                  )}
                </div>
              </Row>
              {/* <Row>
                  <Col md="12">
                    <Select
                      name="role"
                      label="Role"
                      id="role"
                      className="form-control"
                      value={formData.role}
                      onChange={handleInputChange}
                    >
                      <option value="">Select User Role</option>
                      <option value="admin">Admin</option>
                      <option value="attendant">Attendant</option>
                    </Select>
                  </Col>
                </Row> */}
            </>
          </div>
        </Form.Group>
        <Modal.Footer>
          {!showSpinner && (
            <Button
              type="submit"
              id="add_product"
              className="btn btn-primary"
              onClick={handleAddStationAttendant}
            >
              Create
            </Button>
          )}
          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ChargingPointAttendantData;
