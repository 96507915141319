import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../../utils/colors";
import { Col, Modal, Button, Row, Form } from "react-bootstrap";
import Input from "../../../../../components/common/input/input";
import { ToastContainer, toast } from "react-toastify";
import {
  useDeleteUserMutation,
  useDisableUserQuery,
  useAddAUserMutation,
  useGetAllUsersQuery,
  useActivateUserQuery,
} from "../../../../../features/api/users/user";

function UserData() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDisableUser, setShowDisableUser] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleCloseDeleteUser = () => setShowDeleteUser(false);
  const handleCloseDisableUser = () => setShowDisableUser(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
  const [addUser, { isLoading: isAddingUser }] = useAddAUserMutation();
  const {
    data: userData,
    isLoading: userDataLoading,
    refetch: refetchUsers,
  } = useGetAllUsersQuery();

  const { data: disableResponse, refetch: refetchDisableUser } =
    useDisableUserQuery(selectedId, { skip: !selectedId });
  const { data: activateResponse, refetch: refetchActivateUser } =
    useActivateUserQuery(selectedId, { skip: !selectedId });

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = userData?.data
    ? Math.ceil(userData?.data.length / itemsPerPage)
    : 1;

  const currentItems = userData?.data
    ? userData?.data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    : [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddUser = async () => {
    setShowSpinner(true);
    try {
      const userPayload = {
        first_name: formData.firstname,
        last_name: formData.lastname,
        email: formData.email,
        phone_number: formData.phone_number,
        isEmailVerified: true,
        password: "1234567890",
      };

      await addUser(userPayload).unwrap();
      toast.success("User added successfully");
      handleClose();
      refetchUsers();
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Something went wrong");
    } finally {
      setShowSpinner(false);
    }
  };

  const handleDisableUser = (userId) => {
    setSelectedId(userId);
    setShowSpinner(true);
    toast.success("User Disabled succesfully");
    setShowSpinner(false);
    handleCloseDisableUser();
    setShowSpinner(false);
    refetchUsers();
    refetchDisableUser();
  };

  const handleActivateUser = (userId) => {
    setSelectedId(userId);
    setShowSpinner(true);
    toast.success("User Activated succesfully");
    setShowSpinner(false);
    handleCloseDisableUser();
    setShowSpinner(false);
    refetchUsers();
    refetchActivateUser();
  };

  const handleDeleteUser = async (userId) => {
    console.log("dekete ", userId);
    setShowSpinner(true);
    try {
      const data = await deleteUser(userId).unwrap();
      toast.success("User deleted successfully");
      refetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user");
    } finally {
      setShowSpinner(false);
      handleCloseDeleteUser();
    }
  };

  const filteredUsers = currentItems.filter(
    (user) =>
      user.firstname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone_number?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div id="customerList">
      <div className="row g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <button
              type="button"
              className="btn btn-primary add-btn"
              id="create-btn"
              onClick={handleShow}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Users
            </button>
          </div>
        </div>
        <div className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <input
                type="text"
                className="form-control search"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive table-card mt-3 mb-1">
        <table className="table align-middle table-nowrap" id="customerTable">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                  />
                </div>
              </th>
              <th className="sort"></th>
              <th className="sort"> Name</th>
              <th className="sort">Email Address</th>
              <th className="sort">Phone Number</th>
              <th className="sort">Photo</th>
              <th className="sort">Status</th>
              <th className="sort">Actions</th>
            </tr>
          </thead>
          <tbody className="list form-check-all">
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <tr key={user._id}>
                  <th scope="row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk_child"
                        value="option1"
                      />
                    </div>
                  </th>
                  <td></td>
                  <td>{user.firstname + " " + user.lastname}</td>
                  <td>{user.email}</td>
                  <td>{user.phone_number}</td>
                  <td>
                    <img
                      src={
                        user.picture
                          ? user.picture
                          : "/assets/images/users/user-dummy-img.jpg"
                      }
                      alt="Item"
                      className="rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </td>
                  <td className="status">
                    {user.isDisabled === false ? (
                      <span className="badge badge-soft-success text-uppercase">
                        ACTIVE
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        DISABLED
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      <div className="edit">
                        {user.isDisabled === false ? (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setShowDisableUser(true);
                              setSelectedId(user._id);
                            }}
                          >
                            Disable
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              setShowDisableUser(true);
                              setSelectedId(user._id);
                            }}
                          >
                            Activate
                          </button>
                        )}
                      </div>
                      <div className="remove">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setShowDeleteUser(true);
                            setSelectedId(user._id);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No users found.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination summary and navigation */}
        <div className="d-flex justify-content-between align-items-center p-4 mt-4">
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <div>
            <button
              className="btn btn-primary btn-sm me-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Modal show={showDeleteUser} onHide={handleCloseDeleteUser}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Selected User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to delete this user. Deleting the user is
            irreversible. Do you want to continue?
          </p>
        </Modal.Body>
        <Modal.Footer>
          {!showSpinner && (
            <Button className="btn btn-success" onClick={handleCloseDeleteUser}>
              No, Cancel
            </Button>
          )}
          {!showSpinner && (
            <Button
              className="btn btn-danger"
              onClick={() => handleDeleteUser(selectedId)}
            >
              Yes, Delete
            </Button>
          )}
          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showDisableUser} onHide={handleCloseDisableUser}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedId &&
            userData.data.find((user) => user._id === selectedId)
              ?.isDisabled === false
              ? "Disable"
              : "Activate"}{" "}
            Selected User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to{" "}
            {selectedId &&
            userData.data.find((user) => user._id === selectedId)
              ?.isDisabled === false
              ? "disable"
              : "activate"}{" "}
            this user. Do you want to continue?
          </p>
        </Modal.Body>
        <Modal.Footer>
          {!showSpinner && (
            <Button
              className="btn btn-success"
              onClick={handleCloseDisableUser}
            >
              No, Cancel
            </Button>
          )}
          {!showSpinner && (
            <Button
              className="btn btn-danger"
              onClick={() => {
                selectedId &&
                userData.data.find((user) => user._id === selectedId)
                  ?.isDisabled === false
                  ? handleDisableUser(selectedId)
                  : handleActivateUser(selectedId);
              }}
            >
              Yes,{" "}
              {selectedId &&
              userData.data.find((user) => user._id === selectedId)
                ?.isDisabled === false
                ? "Disable"
                : "Activate"}
            </Button>
          )}
          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Form.Group>
          <div style={{ padding: "30px" }}>
            {!email && (
              <>
                <Row>
                  <Col md="12">
                    <Input
                      name="firstname"
                      label="First Name"
                      value={formData.firstname}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input
                      name="lastname"
                      label="Last Name"
                      value={formData.lastname}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      label="Email Address"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      label="Phone Number"
                      type="text"
                    />
                  </Col>
                </Row>
              </>
            )}

            {email && (
              <Row>
                <Col md="12">
                  <Input
                    name="otp"
                    value={formData.otp}
                    onChange={handleInputChange}
                    label="Verification OTP"
                    type="text"
                  />
                </Col>
              </Row>
            )}
          </div>
        </Form.Group>
        <Modal.Footer>
          {!showSpinner && (
            <Button
              type="submit"
              id="add_product"
              className="btn btn-primary"
              onClick={handleAddUser}
            >
              Create
            </Button>
          )}
          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserData;
