import { apiSlice } from "../../baseApi";

export const extendedAmenityApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAmenity: builder.mutation({
      query: (body) => ({
        url: "/amenity",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Amenity"],
    }),

    getAmenity: builder.query({
      query: () => ({
        url: "/amenity",
      }),
      providesTags: (result, query, args) => [{ type: "Amenity" }],
    }),

    updateAmenity: builder.mutation({
      query: (data) => ({
        url: "/amenity",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Amenity"],
    }),
    getAmenityById: builder.query({
      query: (id) => `/amenity/${id}`,
      providesTags: ["Amenity"],
    }),

    deleteAmenity: builder.mutation({
      query: (id) => ({
        url: `/amenity/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Amenity"],
    }),
  }),
});

export const {
  useAddAmenityMutation,
  useDeleteAmenityMutation,
  useGetAmenityQuery,
  useGetAmenityByIdQuery,
  useUpdateAmenityMutation,
} = extendedAmenityApi;
