// import { userApi } from "./userApi";
import { apiSlice } from "../../baseApi";

export const extendedUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addUser: builder.mutation({
      query: (body) => ({
        url: "/user-activity",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),

    getAllUsers: builder.query({
      query: () => ({
        url: "/user/users",
      }),
      invalidatesTags: ["User"],
    }),

    getLoggedInUser: builder.query({
      query: () => ({
        url: "/auth/get-logged-user",
      }),
      invalidatesTags: ["User"],
    }),
    getLoggedInUserByPost: builder.mutation({
      query: (body) => ({
        url: "/auth/post-get-logged-user",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
    getUserPhoneNumberStatus: builder.query({
      query: (id) => ({
        url: `/user/check-phone-number/${id}`,
      }),
    }),
    getUserEmailStatus: builder.query({
      query: (email) => ({
        url: `/user/check-email/${email}`,
      }),
    }),
    disableUser: builder.query({
      query: (id) => ({
        url: `/user/disable-user/${id}`,
      }),
      invalidatesTags: ["User"],
    }),
    activateUser: builder.query({
      query: (id) => ({
        url: `/user/activate-user/${id}`,
      }),
      invalidatesTags: ["User"],
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/user/delete-user/${id}`,
      }),
      invalidatesTags: ["User"],
    }),
    addAUser: builder.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useAddUserMutation,
  useAddAUserMutation,
  useGetUserPhoneNumberStatusQuery,
  useGetUserEmailStatusQuery,
  useGetAllUsersQuery,
  useGetLoggedInUserQuery,
  useGetLoggedInUserByPostMutation,
  useDeleteUserMutation,
  useDisableUserQuery,
  useActivateUserQuery,
} = extendedUserApi;
