import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../../components/header/header";
import DashboardFooter from "../../pages/footer/dashboardFooter";
import NavbarMenu from "../menu/navbarMenu";
import DashboardHome from "./pages/Home";

import User from "./pages/users/list-users";
import ChargingPoint from "./pages/charging-points/list-charging-points";
import VehicleModel from "./pages/vehicle-model/list-vehicle-model";
import VehicleType from "./pages/vehicle-type/list-vehicle-type";
import ConnectionType from "./pages/connection-type/list-connection-type";
import PrivacyPolicy from "./pages/privacy-policy/list-privacy-policy";
import FAQ from "./pages/faq/list-faq";
import TermsAndConditions from "./pages/terms-and-conditions/list-terms-and-conditions";
import AdminUser from "./pages/admin-users";
import Amenities from "./pages/amenities/list-amenities";
import AttendantUser from "./pages/attendants";
import Notification from "./pages/notifications/list-notifications";
import ChargingPointAdminUser from "./pages/charging-point-admin";

import ConnectorsComp from "./pages/attendant-pages/charging-point-connectors";
import ChargingPointAttendantUser from "./pages/charging-point-attendants";
import Bookings from "./pages/bookings";
import StationBookings from "./pages/station-bookings";

const Dashboard = () => {
  return (
    <div id="layout-wrapper">
      <Header />
      <NavbarMenu />
      <div className="main-content">
        <Routes>
          <Route exact path="" element={<DashboardHome />} />
          <Route exact path="/users" element={<User />} />
          <Route exact path="/charging-point" element={<ChargingPoint />} />
          <Route exact path="/vehicle-model" element={<VehicleModel />} />
          <Route exact path="/amenities" element={<Amenities />} />
          <Route exact path="/vehicle-type" element={<VehicleType />} />
          <Route exact path="/connection-type" element={<ConnectionType />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/admin-users" element={<AdminUser />} />
          <Route exact path="/attendants" element={<AttendantUser />} />
          <Route
            exact
            path="/charging-point-admin"
            element={<ChargingPointAdminUser />}
          />
          <Route exact path="/notifications" element={<Notification />} />
          <Route exact path="/connectors" element={<ConnectorsComp />} />
          <Route exact path="/bookings" element={<Bookings />} />
          <Route exact path="/station-bookings" element={<StationBookings />} />
          <Route
            exact
            path="/charging-point-attendants"
            element={<ChargingPointAttendantUser />}
          />
        </Routes>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default Dashboard;
