import { configureStore } from "@reduxjs/toolkit";
import { categoryApi } from "../features/api/categories/categoryApi";
// import authApi from "../features/api/auth/login";
import { userApi } from "../features/api/users/userApi";
import { apiSlice } from "../features/baseApi";
import { authApi } from "../features/api/auth/login";

export const store = configureStore({
  reducer: {
    [categoryApi.reducerPath]: categoryApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      categoryApi.middleware,
      authApi.middleware,
      userApi.middleware,
      apiSlice.middleware
    ),
});
