import React from "react";

function Checkbox({ id, checked, disabled, label, name, onChange }) {
  return (
    <div className="form-check form-switch">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
}

export default Checkbox;
