import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import { useLoginMutation } from "../../../features/api/auth/login";
import SignInButton from "./loginBotton/signInButton";
import Input from "../../../components/common/input/input";
import Loader from "../../../loader/loader";
import { UserContext } from "../../../context/userContext";
import {
  useGetLoggedInUserQuery,
  useGetLoggedInUserByPostMutation,
} from "../../../features/api/users/user";

export default function LoginForm() {
  const [formState, setFormState] = useState({
    account: { email: "", password: "" },
  });
  const { setUser, currentUser } = useContext(UserContext);

  const { data: loggedInUserData, isLoading: loggedInUserLoading } =
    useGetLoggedInUserQuery();
  const [Login, { isLoading: loginLoading, error }] = useLoginMutation();

  const [
    GetLoggedInUser,
    { isLoading: loggedInPersonLoading, error: loggedInPersonError },
  ] = useGetLoggedInUserByPostMutation();

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const schema = {
    email: Joi.string().email().required().label("Email"),
    password: Joi.string().required().min(2).label("Password"),
  };

  const { account } = formState;

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, schema, options);
    if (!error) return null;

    const errors = {};

    error.details.map((item) => {
      return (errors[item.path[0]] = item.message);
    });

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    // ES6 computed property name syntax picked dynamically at run time; syntax: [name]
    const input_obj = { [name]: value };
    const schema_obj = { [name]: schema[name] };
    const { error } = Joi.validate(input_obj, schema_obj);

    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const errorsClone = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) {
      errorsClone[input.name] = errorMessage;
    } else {
      delete errorsClone[input.name];
    }

    const data = { ...formState.account };
    data[input.name] = input.value;

    setFormState({ account: data });
    setErrors(errorsClone);
  };

  const location = useLocation();

  const loaderSpinning = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (errors) {
      setErrors(errors);
      return;
    }

    loaderSpinning();
    try {
      const { data } = await Login({
        email: account.email,
        password: account.password,
      });

      console.log("datata", data);
      // if (data.role !== "admin") {
      //   return toast.error("Error Authenticating User");
      // }

      console.log("datataatt", data);
      toast.info(data?.message)
      if (!data?.acessToken) {
        return toast.error("Error Authenticating User");
      }
      if (data.role === "user") {
        toast.error("Unauthorized user");
        return false;
      }
      const encryptedRole = btoa(data.role);
      localStorage.setItem("user-role", encryptedRole);

      localStorage.setItem("admin-token", data.acessToken);
      console.log("hehehe 1");

      const usrr = await GetLoggedInUser({ token: data.accessToken });
      const encryptedUser = btoa(JSON.stringify(usrr.data.user));
      localStorage.setItem("user", encryptedUser);
      // setUser(usrr?.data.user);
      console.log("hehehe 12");
      navigate("/dashboard");
      // window.location.replace("/dashboard");
      toast.success("Login Successful");

      console.log("hehehe 13");
    } catch (error) {
      console.log("err", error);
      toast.error("Login Unsuccessful", error.message);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card mt-4">
          <div className="card-body p-4">
            <div className="text-center mt-2">
              <h5 className="" style={{ color: "#571AB7" }}>
                Welcome Back!
              </h5>
              <p className="text-muted">Sign in to continue</p>
            </div>
            <div className="p-2 mt-4">
              <form onSubmit={handleSubmit}>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  placeholder={"Enter email"}
                  error={errors.email}
                  value={account.email}
                  onChange={handleChange}
                />
                <Input
                  name="password"
                  label="Password"
                  type="password"
                  placeholder={"Enter password"}
                  error={errors.password}
                  value={account.password}
                  onChange={handleChange}
                />
                <SignInButton onDisabled={validate()} />
                <ToastContainer />
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
