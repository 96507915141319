import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Dashboard from "./views/dashboard/Dashboard";
import Login from "./views/auth/loginEntry/Login";
import { UserContext } from "./context/userContext";
import Logout from "./views/auth/logoutForm/Logout";
import PrivateRoutes from "../src/utils/privateRouter";

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  return (
    <UserContext.Provider value={{ currentUser, setUser: setCurrentUser }}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          {/* Private routes */}
          <Route element={<PrivateRoutes />}>
            {/* Protected routes */}
            <Route path="/dashboard/*" element={<Dashboard />} />
            {/* Add more protected routes here */}
          </Route>

          {/* Redirect to dashboard by default */}
          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
