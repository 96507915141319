import { apiSlice } from "../../baseApi";

export const extendedChargingPointAdminUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChargingPointAdminUsers: builder.query({
      query: () => ({
        url: "/charging-point-admin",
      }),
      invalidatesTags: ["ChargingPoint"],
    }),
    addChargingPointAdminUsers: builder.mutation({
      query: (body) => ({
        url: `/charging-point-admin`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ChargingPoint"],
    }),
   
  }),
});

export const { useGetChargingPointAdminUsersQuery, useAddChargingPointAdminUsersMutation } =
  extendedChargingPointAdminUserApi;
