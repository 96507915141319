import React from 'react'

function Select({ name, label, error, children, ...rest }) {
    return (
        <div className="mb-3">
            <label htmlFor={name} className="form-label">
                {label}
            </label>
            <select 
                id={name}
                name={name} 
                {...rest}
                className="form-control"
            >
                {children}
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );

}

export default Select