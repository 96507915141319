import { apiSlice } from "../../baseApi";

export const extendedPrivacyPolicyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addPrivacyPolicy: builder.mutation({
      query: (body) => ({
        url: "/privacy-policy",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["PrivacyPolicy"],
    }),

    getPrivacyPolicy: builder.query({
      query: () => ({
        url: "/privacy-policy",
      }),
      providesTags: (result, query, args) => [{ type: "PrivacyPolicy" }],
    }),

    updatePrivacyPolicy: builder.mutation({
      query: (data) => ({
        url: "/privacy-policy",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["PrivacyPolicy"],
    }),
    getPrivacyPolicyById: builder.query({
      query: (id) => `/privacy-policy/${id}`,
      providesTags: ["PrivacyPolicy"],
    }),

    deletePrivacyPolicy: builder.mutation({
      query: (id) => ({
        url: `/privacy-policy/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PrivacyPolicy"],
    }),
  }),
});

export const {
  useAddPrivacyPolicyMutation,
  useDeletePrivacyPolicyMutation,
  useGetPrivacyPolicyByIdQuery,
  useGetPrivacyPolicyQuery,
  useUpdatePrivacyPolicyMutation,
} = extendedPrivacyPolicyApi;
