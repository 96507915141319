import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../utils/colors";
import { Col, Modal, Button, Row, Form } from "react-bootstrap";
import Input from "../../../../components/common/input/input";
import { useVerifyAdminUserMutation } from "../../../../features/api/admin-user/adminUserApi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { useAddAttendantMutation } from "../../../../features/api/attendant-user/attendantApi";
import { useGetUserPhoneNumberStatusQuery } from "../../../../features/api/users/user";
import { useGetUserEmailStatusQuery } from "../../../../features/api/users/user";
import {
  useAddChargingPointAdminUsersMutation,
  useGetChargingPointAdminUsersQuery,
} from "../../../../features/api/charging-point-admin/chargingPointAdminApi";

function ChargingPointAdminData({ metadata, currentPage }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(false);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [currentPg, setCurrentPg] = useState(currentPage || 1);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
  });

  const [saveAdminUser] = useAddChargingPointAdminUsersMutation();
  const [verifyAdmin] = useVerifyAdminUserMutation();
  const handleCloseDelete = () => setShowDelete(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const itemsPerPage = 20;
  const pages = [];

  for (let i = 0; i < Math.ceil(metadata.total / itemsPerPage); i++) {
    pages.push(i + 1);
  }

  const {
    data: phoneNumberCheck,
    error,
    isLoading,
  } = useGetUserPhoneNumberStatusQuery(formData.phone_number, {
    skip: !formData.phone_number,
  });

  const {
    data: adminUserList,
    refetch: refetchAdminUserList,
    loading: adminUserListLoading,
  } = useGetChargingPointAdminUsersQuery({
    page: currentPg,
    limit: itemsPerPage,
  });

  const {
    data: emailCheck,
    error: emailCheckError,
    isLoading: emailCheckLoading,
  } = useGetUserEmailStatusQuery(formData.email, { skip: !formData.email });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const verifyEmail = async () => {
    try {
      const { data } = await verifyAdmin({
        email: formData.email,
        code: formData.otp,
      });
      if (data.success === true) {
        return toast.success("Attendant created successfully");
      } else {
        return toast.error("Error creating Attendant", data);
      }
    } catch (error) {
      return toast.error("An error occurred while verifying Attendant");
    } finally {
      setShowSpinner(false);
      handleClose();
    }
  };

  const handleAddChargingPointAdmin = async (e) => {
    try {
      e.preventDefault();
      setShowSpinner(true);

      const data = await saveAdminUser({
        first_name: formData.firstname,
        last_name: formData.lastname,
        email: formData.email,
        phone_number: formData.phone_number,
        role: "charging-point-admin",
        isEmailVerified: true,
        password: "1234567890",
      });

      if (data?.data?.success === true) {
        toast.success("Station admin added successfully");
        refetchAdminUserList(); // Trigger refetch after successful add
        return;
      }

      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        otp: "",
        role: "",
      });
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setShowSpinner(false);
      setShow(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      checkPhoneNumberExists();
    }, 300);

    return () => clearTimeout(delay);
  }, [formData.phone_number]);

  useEffect(() => {
    const delay = setTimeout(() => {
      checkEmailExists();
    }, 300);

    return () => clearTimeout(delay);
  }, [formData.email]);

  const checkPhoneNumberExists = async () => {
    try {
      const response = await fetch(
        `/api/user/check-phone-number/${formData.phone_number}`
      );
      const data = await response.json();

      if (response.ok) {
        setPhoneNumberExists(data.exists);
      }
    } catch (error) {
      console.error("Failed to fetch:", error.message);
    }
  };

  const checkEmailExists = async () => {
    try {
      const response = await fetch(`/api/user/check-email/${formData.email}`);
      const data = await response.json();

      if (response.ok) {
        setEmailExists(data.exists);
      }
    } catch (error) {
      console.error("Failed to fetch:", error.message);
    }
  };

  const handleInputChangeDebounced = debounce(handleInputChange, 300);

  useEffect(() => {
    if (phoneNumberCheck) {
      setPhoneNumberExists(phoneNumberCheck.exists);
    }
    if (error) {
      console.error("Error checking phone number:", error);
    }
  }, [phoneNumberCheck, error]);

  useEffect(() => {
    if (emailCheck) {
      setEmailExists(emailCheck.exists);
    }
    if (emailCheckError) {
      console.error("Error checking email:", emailCheckError);
    }
  }, [emailCheck, emailCheckError]);

  const handlePageChange = (page) => {
    setCurrentPg(page);
  };

  return (
    <div id="customerList">
      <div className="row g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <button
              type="button"
              className="btn btn-primary add-btn"
              id="create-btn"
              onClick={handleShow}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Charging
              Point Admin
            </button>
          </div>
        </div>
        <div className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <input
                type="text"
                className="form-control search"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive table-card mt-3 mb-1">
        <table className="table align-middle table-nowrap" id="customerTable">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                  />
                </div>
              </th>
              <th className="sort">S/N</th>
              <th className="sort">Picture</th>
              <th className="sort"> Name</th>
              <th className="sort">Email</th>
              <th className="sort">Phone Number</th>
              <th className="sort">Email Verification</th>
              <th className="sort">Status</th>
              <th className="sort">Actions</th>
            </tr>
          </thead>
          <tbody className="list form-check-all">
            {adminUserList && adminUserList.data.length > 0 ? (
              adminUserList?.data.map((item, index) => (
                <tr key={item._id}>
                  <th scope="row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk_child"
                        value="option1"
                      />
                    </div>
                  </th>
                  <td>{(currentPg - 1) * itemsPerPage + index + 1}</td>
                  <td>
                    <img
                      src={
                        item.picture
                          ? item.picture
                          : "/assets/images/users/user-dummy-img.jpg"
                      }
                      alt="Item"
                      className="rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>{item.firstname + " " + item.lastname}</td>
                  <td>{item.email}</td>
                  <td>{item.phone_number}</td>
                  <td className="status">
                    {item.isDisabled === false ? (
                      <span className="badge badge-soft-success text-uppercase">
                        Verified
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        Inactive
                      </span>
                    )}
                  </td>
                  <td className="status">
                    {item?.isDisabled === false ? (
                      <span className="badge badge-soft-success text-uppercase">
                        ACTIVE
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        DISABLED
                      </span>
                    )}
                  </td>

                  <td>
                    <div className="d-flex gap-2">
                      <div className="edit">
                        <Link
                          className="btn btn-sm btn-success edit-item-btn"
                          onClick={() => {
                            setShowDelete(true);
                          }}
                        >
                          Open
                        </Link>
                      </div>
                      <div className="remove">
                        <button
                          className="btn btn-sm btn-danger remove-item-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteRecordModal"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9">No users found.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination summary and navigation */}
        <div
          className="d-flex mx-3"
          style={{ justifyContent: "space-between" }}
        >
          <div
            role="status"
            aria-live="polite"
            className="gridjs-summary"
            title={`Page ${currentPg} of ${pages.length}`}
          >
            Showing <b>{(currentPg - 1) * itemsPerPage + 1}</b> to{" "}
            <b>{(currentPg - 1) * itemsPerPage + adminUserList?.data.length}</b>{" "}
            of <b>{metadata.total}</b> results
          </div>

          <div className="pagination-wrap hstack gap-2">
            <button
              className={`page-item pagination-prev ${
                currentPg === 1 ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPg - 1)}
              disabled={currentPg === 1}
            >
              Previous
            </button>
            <ul className="pagination listjs-pagination mb-0">
              {pages.map((page) => (
                <li
                  key={page}
                  className={`${currentPg === page ? "active" : ""}`}
                >
                  <button
                    className="page"
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
            <button
              className={`page-item pagination-next ${
                currentPg === pages.length ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPg + 1)}
              disabled={currentPg === pages.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Charging Point Admin</Modal.Title>
        </Modal.Header>
        <form action="" onSubmit="">
          <Modal.Body>
            <p>
              You are about to delete <strong>{}</strong>, deleting the featured
              product is irreversible. Do you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button className="btn btn-success" onClick={handleCloseDelete}>
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button className="btn btn-danger" type="submit">
                Yes, Delete
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Charging Point Admin</Modal.Title>
        </Modal.Header>
        <Form.Group>
          <div style={{ padding: "30px" }}>
            {!email && (
              <>
                <Row>
                  <Col md="12">
                    <Input
                      name="firstname"
                      label="First Name"
                      value={formData.firstname}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input
                      name="lastname"
                      label="Last Name"
                      value={formData.lastname}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      label="Email Address"
                      type="text"
                    />
                  </Col>
                </Row>
                {emailExists && (
                  <p style={{ color: "red" }}>Email address is already taken</p>
                )}
                <Row>
                  <Col md="12">
                    <Input
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      label="Phone Number"
                      type="text"
                    />
                  </Col>
                  <div>
                    {phoneNumberExists && (
                      <p style={{ color: "red" }}>
                        Phone number is already taken
                      </p>
                    )}
                  </div>
                </Row>
              </>
            )}
            {email && (
              <Row>
                <Col md="12">
                  <Input
                    name="otp"
                    value={formData.otp}
                    onChange={handleInputChange}
                    label="Verification OTP"
                    type="text"
                  />
                </Col>
              </Row>
            )}
          </div>
        </Form.Group>
        <Modal.Footer>
          {!showSpinner && (
            <Button
              type="submit"
              id="add_product"
              className="btn btn-primary"
              onClick={handleAddChargingPointAdmin}
            >
              Create
            </Button>
          )}
          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ChargingPointAdminData;
