import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Modal, Button, Row, Form, input } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../../utils/colors";
import Input from "../../../../../components/common/input/input";
import { ToastContainer, toast } from "react-toastify";
import {
  useAddConnectionTypeMutation,
  useDeleteConnectionTypeMutation,
} from "../../../../../features/api/connection-type/connectionTypeAPI";

function ConnectionTypeData({ data, metadata, currentPage }) {
  const navigate = useNavigate();
  const pages = [];
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [
    deleteVehicleType,
    { loading: deleteConnectionTypeLoading, error: connectionTypeError },
  ] = useDeleteConnectionTypeMutation();

  const [addConnectionType] = useAddConnectionTypeMutation();

  for (let i = 0; i < Math.ceil(metadata.total / 20); i++) {
    pages.push(i + 1);
  }

  const handleDeleteItem = async () => {
    try {
      const { data } = await deleteVehicleType(selectedId);

      if (data.success === true) {
        toast.success(data.message);
        handleCloseDelete();
      }
      console.log(data);
    } catch (error) {
      console.log("error", error.message);
      toast.error("An error occured");
    } finally {
      setSelectedId(null);
    }
  };

  const handleAddVehicleType = async () => {
    try {
      setLoading(true);

      const { data } = await addConnectionType({
        name,
        description,
      });
      console.log("data", data);
      if (data.success === true) {
        toast.success("Vehicle type added successfully");
      }
    } catch (error) {
      console.error("Error adding vehicle type:", error);

      toast.error("Failed to add vehicle type. Please try again later.");
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div id="customerList">
      <div className="row g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <button
              type="button"
              className="btn btn-success add-btn"
              id="create-btn"
              onClick={handleShow}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Connection
              Type
            </button>
          </div>
        </div>
        <div className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <input
                type="text"
                className="form-control search"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive table-card mt-3 mb-1">
        <table className="table align-middle table-nowrap" id="customerTable">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                  />
                </div>
              </th>
              <th className="sort">S/N</th>
              <th className="sort"> Name</th>
              <th className="sort"> Description</th>
              <th className="sort">Actions</th>
            </tr>
          </thead>
          <tbody className="list form-check-all">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={item.id}>
                  <th scope="row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk_child"
                        value="option1"
                      />
                    </div>
                  </th>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>

                  <td>
                    <div className="d-flex gap-2">
                      <div className="edit">
                        <Link
                          to={`${item._id}`}
                          className="btn btn-sm btn-success edit-item-btn"
                        >
                          Open
                        </Link>
                      </div>
                      <div className="remove">
                        <button
                          className="btn btn-sm btn-danger remove-item-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteRecordModal"
                          onClick={() => {
                            handleShowDelete();
                            setSelectedId(item._id);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No connection type found.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* --------------------------------------------------------------------------------------------------- ------*/}
        <div
          className="d-flex mx-3"
          style={{ justifyContent: "space-between" }}
        >
          <div
            role="status"
            aria-live="polite"
            class="gridjs-summary"
            title="Page 1 of 2"
          >
            Showing <b>{(currentPage - 1) * 20 + 1}</b> to{" "}
            <b>
              {(currentPage - 1) * 20 + 20 <= metadata.total
                ? (currentPage - 1) * 20 + 20
                : metadata.total}
            </b>{" "}
            of <b>{metadata.total}</b> results
          </div>

          <div className="pagination-wrap hstack gap-2">
            <Link
              className={`page-item pagination-prev ${
                !metadata.prevPage ? "disabled" : ""
              }`}
              to={`${
                metadata.prevPage
                  ? "/dashboard/products?page=" + metadata.prevPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Previous
            </Link>
            <ul className="pagination listjs-pagination mb-0">
              {pages.map((page) => (
                <li class={`${currentPage == page ? "active" : ""}`}>
                  <Link
                    class="page"
                    to={`/dashboard/products?page=${page}`}
                    data-i="1"
                    data-page="8"
                  >
                    {page}
                  </Link>
                </li>
              ))}
            </ul>
            <Link
              className={`page-item pagination-next ${
                !metadata.nextPage ? "disabled" : ""
              }`}
              to={`${
                metadata.nextPage
                  ? "/dashboard/products?page=" + metadata.nextPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Next
            </Link>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Connection Type</Modal.Title>
        </Modal.Header>
        <Form.Group>
          <div style={{ padding: "30px" }}>
            <>
              <Row>
                <Col md="12">
                  <Input
                    name="name"
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Input
                    id="description"
                    name="description"
                    label=" Description"
                    value={description}
                    type="text"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
              </Row>
            </>
          </div>
        </Form.Group>
        <Modal.Footer>
          <Button
            type="submit"
            id="add_charginp_point"
            className="btn btn-primary"
            onClick={handleAddVehicleType}
          >
            {loading ? "Loading ..." : "Create"}
          </Button>

          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Connection Type</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <p>
              You are about to delete <strong>{}</strong>, deleting the
              connection type is irreversible. Do you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button className="btn btn-primary" onClick={handleCloseDelete}>
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button className="btn btn-danger" onClick={handleDeleteItem}>
                Yes, Delete
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default ConnectionTypeData;
