import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../src/utils/config";

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("admin-token");

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
  timeout: 60000,
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: ["User "],
  endpoints: (builder) => ({}),
});
