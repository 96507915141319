import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/userContext";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";

const NavbarUserDetail = () => {
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const encryptedUser = localStorage.getItem("user");
    const decryptedUserJSON = atob(encryptedUser);
    const decryptedUser = JSON.parse(decryptedUserJSON);
    setUser(decryptedUser);
  }, []);

  return (
    <div className="d-flex align-items-center">
      <div className="dropdown d-md-none topbar-head-dropdown header-item">
        <button
          type="button"
          className="btn btn__user btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          id="page-header-search-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {/* {user.firstName} */}
        </button>
      </div>
      <Dropdown>
        <Dropdown.Toggle variant="red">
          <img
            src={
              user?.picture
                ? user?.picture
                : "/assets/images/users/user-dummy-img.jpg"
            }
            alt="Avatar"
            className="rounded-circle border border-solid border-dark"
            style={{ width: "50px", height: "50px" }}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Settings</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/logout")}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default NavbarUserDetail;
