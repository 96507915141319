import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Modal, Button, Row, Form, input } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../../utils/colors";
import Input from "../../../../../components/common/input/input";
import Select from "../../../../../components/common/select";
import { ToastContainer, toast } from "react-toastify";
import CheckBox from "../../../../../components/common/checkbox";
import { useGetConnectionTypeQuery } from "../../../../../features/api/connection-type/connectionTypeAPI";
import { useGetAmenityQuery } from "../../../../../features/api/amenity/amenitiesApui";
import {
  useAddChargingPointMutation,
  useDeleteChargingPointMutation,
  useApproveChargingPointMutation,
  useGetChargingPointByIdQuery,
} from "../../../../../features/api/charging-point/chargingPointAPI";
import { useGetChargingPointAdminUsersQuery } from "../../../../../features/api/charging-point-admin/chargingPointAdminApi";

function ChargingPointData({ chargingPoints, metadata, currentPage }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [show, setShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connectionType, setConnectionType] = useState();
  const [amenity, setAmenity] = useState();
  const [connectionTypeVal, setConnectionTypeVal] = useState([]);
  const [amenityVal, setAmenityVal] = useState([]);
  const [selectedchargingPoint, setSelectedChargingPoint] = useState();

  const [AddChargingPoint] = useAddChargingPointMutation();
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleCloseApprove = () => setShowApprove(false);
  const handleShowApprove = () => setShowApprove(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    data: newConnectionType,
    isLoading,
    isError,
  } = useGetConnectionTypeQuery();

  const { data: adminUserList, loading: adminUserListLoading } =
    useGetChargingPointAdminUsersQuery();

  const {
    data: chargingPointData,
    loading: aChargingPointDataLoading,
    error: aChargingPointDataError,
  } = useGetChargingPointByIdQuery(selectedchargingPoint);

  const [
    deleteChargingPoint,
    { loading: deleteChargingPointLoading, error: deleteChargingPointError },
  ] = useDeleteChargingPointMutation();

  const [
    approveChargingPoint,
    {
      loading: updateChargingPointLoading,
      error: updateChargingPointLoadingError,
    },
  ] = useApproveChargingPointMutation();
  const handleView = (id) => {
    setSelectedChargingPoint(id);
  };
  const {
    data: amenityList,
    isLoading: amenityLoading,
    isError: amenityError,
  } = useGetAmenityQuery();

  const handleCheckboxChange = (id) => {
    // Log the selected ID
    console.log("Selected ID:", id);

    const index = formData.amenities.indexOf(id);
    if (index === -1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        amenities: [...prevFormData.amenities, id],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        amenities: prevFormData.amenities.filter((item) => item !== id),
      }));
    }

    // Log the updated array of selected IDs
    console.log("Selected Amenities:", formData.amenities);
  };

  const handleDeleteAChargingPoint = async () => {
    try {
      const { data } = await deleteChargingPoint(selectedchargingPoint);
      if (data.success === true) {
        toast.success(data.message);
        handleCloseDelete();
      }
      console.log(data);
    } catch (error) {
      console.log("error", error.message);
      toast.error("An error occured");
    } finally {
      setSelectedChargingPoint(null);
    }
  };


const handleMultipleSelectChange = (e) => {
  const { name, options } = e.target;
  const selectedValues = [];
  for (let i = 0; i < options.length; i++) {
    if (options[i].selected) {
      selectedValues.push(options[i].value);
    }
  }
  setFormData({
    ...formData,
    [name]: selectedValues,
  });
};
  const handleApproveChargingPoint = async () => {
    try {
      const { data } = await approveChargingPoint(selectedchargingPoint);

      return console.log("daa", data);
      if (data.success === true) {
        toast.success(data.message);
        handleCloseDelete();
      }
      console.log(data);
    } catch (error) {
      console.log("error", error.message);
      toast.error("An error occured");
    } finally {
      setSelectedChargingPoint(null);
      handleCloseApprove();
    }
  };

  useEffect(() => {
    if (newConnectionType) {
      setConnectionType(newConnectionType?.connectionTypes);
    }
    if (amenityList) {
      setAmenity(amenityList.amenities);
    }
  }, [newConnectionType]);

  useEffect(() => {
    if (amenityList) {
      setAmenity(amenityList.amenities);
    }
  }, [amenityList]);

  const [formData, setFormData] = useState({
    name: "",
    location: "",
    connectionType: [],
    amenities: [],
    longitude: "",
    latitude: "",
    admin_user: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddAdmin = async (e) => {
    try {
      e.preventDefault();
      setShowSpinner(true);
      setLoading(true);
      if (!formData.name) {
        toast.error("Please enter a name.");
        return;
      }

      if (!formData.location) {
        toast.error("Please enter a location.");
        return;
      }

      if (!formData.connectionType) {
        toast.error("Please select a connection type.");
        return;
      }

      if (formData.amenities.length === 0) {
        toast.error("Please select at least one amenity.");
        return;
      }

      if (!formData.longitude) {
        toast.error("Please enter a longitude.");
        return;
      }

      if (!formData.latitude) {
        toast.error("Please enter a latitude.");
        return;
      }
      if (!formData.admin_user) {
        toast.error("Please enter a Admin User.");
        return;
      }
      
      console.log("sssss", formData);
      const { data } = await AddChargingPoint({
        name: formData.name,
        location: formData.location,
        connectionType: formData.connectionType,
        latitude: formData.latitude,
        longitude: formData.longitude,
        file: formData.file,
        amenities: formData.amenities,
        admin_user: formData.admin_user,
      });

      if (data.success === true) {
        toast.success("A charging point added successfully");
      } else {
        toast.error("Something went wrong");
      }

      setFormData({
        name: "",
        location: "",
        connectionType: "",
        amenities: [],
        longitude: "",
        latitude: "",
      });
    } catch (error) {
      toast.error("An error occured");
    } finally {
      setShowSpinner(false);
      setLoading(false);
      handleClose();
    }
  };

  const navigate = useNavigate();
  const pages = [];

  for (let i = 0; i < Math.ceil(metadata.total / 20); i++) {
    pages.push(i + 1);
  }

  return (
    <div id="customerList">
      <div className="row g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <button
              type="button"
              className="btn btn-primary add-btn"
              id="create-btn"
              onClick={handleShow}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Charging
              Point
            </button>
          </div>
        </div>
        <div className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <input
                type="text"
                className="form-control search"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive table-card mt-3 mb-1">
        <table className="table align-middle table-nowrap" id="customerTable">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                  />
                </div>
              </th>
              <th className="sort"></th>
              <th className="sort"> Name</th>
              <th className="sort">Location</th>
              <th className="sort">Amenities</th>
              <th className="sort">Approval Status</th>
              <th className="sort">Status</th>
              <th className="sort">Actions</th>
            </tr>
          </thead>
          <tbody className="list form-check-all">
            {chargingPoints && chargingPoints.length > 0 ? (
              chargingPoints.map((data) => (
                <tr key={data.id}>
                  <th scope="row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk_child"
                        value="option1"
                      />
                    </div>
                  </th>
                  <td>
                    {/* <img src={product.primary_image?.thumbnail?.url} alt="" /> */}
                  </td>
                  <td>{data.name}</td>
                  <td>{data.location}</td>
                  <td>{data?.connectionType?.name}</td>
                  <td>
                    {data.approved == true ? (
                      <span className="badge badge-soft-success text-uppercase">
                        Approved
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        Not Approved
                      </span>
                    )}
                  </td>
                  <td className="status">
                    {data.status === "approved" ? (
                      <span className="badge badge-soft-success text-uppercase">
                        Approved
                      </span>
                    ) : (
                      <span className="badge badge-soft-danger text-uppercase">
                        Not Approved
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      <div className="edit">
                        {/* <Link
                          // to={`${data._id}`}
                          className="btn btn-sm btn-primary edit-item-btn"
                          onClick={() => {
                            handleView(data._id);
                            setSelectedChargingPoint(data._id);
                          }}
                        >
                          Open
                        </Link> */}
                      </div>
                      <div className="edit">
                        <Link
                          // to={`${data._id}`}
                          className="btn btn-sm btn-primary edit-item-btn"
                          onClick={() => {
                            handleView(data._id);
                            setSelectedChargingPoint(data._id);
                          }}
                        >
                          Update
                        </Link>
                      </div>
                      <div className="remove">
                        <button
                          className={`btn btn-sm btn-success remove-item-btn ${
                            data.approved ? "disabled" : ""
                          }`}
                          data-bs-toggle="modal"
                          data-bs-target="#approveRecordModal"
                          onClick={() => {
                            handleShowApprove();
                            setSelectedChargingPoint(data._id);
                          }}
                          disabled={data.approved}
                        >
                          {data.approved ? "Approved" : "Approve"}
                        </button>
                      </div>
                      <div className="remove">
                        <button
                          className="btn btn-sm btn-danger remove-item-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteRecordModal"
                          onClick={() => {
                            handleShowDelete();
                            setSelectedChargingPoint(data._id);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No users found.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* --------------------------------------------------------------------------------------------------- ------*/}
        <div
          className="d-flex mx-3"
          style={{ justifyContent: "space-between" }}
        >
          <div
            role="status"
            aria-live="polite"
            class="gridjs-summary"
            title="Page 1 of 2"
          >
            Showing <b>{(currentPage - 1) * 20 + 1}</b> to{" "}
            <b>
              {(currentPage - 1) * 20 + 20 <= metadata.total
                ? (currentPage - 1) * 20 + 20
                : metadata.total}
            </b>{" "}
            of <b>{metadata.total}</b> results
          </div>

          <div className="pagination-wrap hstack gap-2">
            <Link
              className={`page-item pagination-prev ${
                !metadata.prevPage ? "disabled" : ""
              }`}
              to={`${
                metadata.prevPage
                  ? "/dashboard/products?page=" + metadata.prevPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Previous
            </Link>
            <ul className="pagination listjs-pagination mb-0">
              {pages.map((page) => (
                <li class={`${currentPage == page ? "active" : ""}`}>
                  <Link
                    class="page"
                    to={`/dashboard/products?page=${page}`}
                    data-i="1"
                    data-page="8"
                  >
                    {page}
                  </Link>
                </li>
              ))}
            </ul>
            <Link
              className={`page-item pagination-next ${
                !metadata.nextPage ? "disabled" : ""
              }`}
              to={`${
                metadata.nextPage
                  ? "/dashboard/products?page=" + metadata.nextPage
                  : "/dashboard/products?page=" + currentPage
              }`}
            >
              Next
            </Link>
          </div>
        </div>
      </div>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Charging Product</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <p>
              You are about to delete <strong>{}</strong>, deleting the featured
              product is irreversible. Do you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button className="btn btn-primary" onClick={handleCloseDelete}>
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button
                className="btn btn-danger"
                onClick={handleDeleteAChargingPoint}
              >
                Yes, Delete
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showApprove} onHide={handleCloseApprove}>
        <Modal.Header closeButton>
          <Modal.Title>Approve Charging Point</Modal.Title>
        </Modal.Header>
        <form action="" onSubmit="">
          <Modal.Body>
            <p>
              You are about to approve <strong>{}</strong>, approving the
              charging point is irreversible. Do you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button className="btn btn-primary" onClick={handleCloseApprove}>
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button
                className="btn  btn-success "
                onClick={handleApproveChargingPoint}
              >
                Yes, Approve
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Charging Point</Modal.Title>
        </Modal.Header>
        <Form.Group>
          <div style={{ padding: "30px" }}>
            <>
              <Row>
                <Col md="12">
                  <Select
                    name="admin_user"
                    label="Admin User"
                    id="admin_user"
                    className="form-control"
                    value={formData.admin_user}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Admin User</option>
                    {adminUserList &&
                      adminUserList.data.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user?.lastname + " " + user?.firstname + "  " + 'Email:' + user?.email}
                        </option>
                      ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Input
                    name="name"
                    label="Charging point name"
                    value={formData.name}
                    onChange={handleInputChange}
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Input
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    label="Charging point location"
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Select
              
                    name="connectionType"
                    label="Connection Type"
                    id="connection-type"
                    className="form-control"
                    value={formData.connectionType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Connection Type</option>
                    {connectionType?.map((item) => (
                     <option key={item._id} value={item._id}>

                      {item?.name}
                     </option>
                     
                    ))}
                  </Select>
           
                </Col>
              </Row>
              
              <Row>
                <Col md="12">
                  <Input
                    name="file"
                    label="Photo"
                    type="file"
                    multiple="multiple"
                    // error={}
                    // onChange={}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Input
                    name="longitude"
                    label="Longitude"
                    value={formData.longitude}
                    onChange={handleInputChange}
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
                <Col md="6">
                  <Input
                    name="latitude"
                    label="Latitude"
                    value={formData.latitude}
                    onChange={handleInputChange}
                    type="text"
                    // onChange={handleNewFileSelect}
                  />
                </Col>
              </Row>
            </>

            <Row>
      <label>Select Amenities</label>
      {amenity?.length === 0 ? (
        <p className="badge badge-soft-danger text-uppercase">
          CREATE AMENITIES BEFORE YOU PROCEED
        </p>
      ) : (
        amenity?.map((item) => (
          <Col md="6" key={item._id}>
            <CheckBox
              name="amenities"
              id={`flexSwitchCheck${item._id}`}
              label={item.name}
              // checked={formData.amenities.includes(item._id)}
              onChange={() => handleCheckboxChange(item._id)}
            />
          </Col>
        ))
      )}
    </Row>
          </div>
        </Form.Group>
        <Modal.Footer>
          <Button
            type="submit"
            id="add_charginp_point"
            className="btn btn-primary"
            onClick={handleAddAdmin}
          >
            {!loading ? "Create " : "Loading ..."}
          </Button>

          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ChargingPointData;

