import React from "react";

import Search from "../common/search/search";
import NavbarUserDetail from "../nav/navbarUser/navbarUserDetail";
import MobileNavbar from "./mobileNav";

export default function Header() {
  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <Search />
          </div>
          <NavbarUserDetail />
        </div>
      </div>
    </header>
  );
}
