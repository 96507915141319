import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  useEffect(() => {
    const token = localStorage.getItem("admin-token");
  }, []);

  const authToken = localStorage.getItem("admin-token");

  // If authToken is null or undefined, user is not authenticated
  const isAuthenticated = authToken !== null && authToken !== undefined;

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
