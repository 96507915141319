import { apiSlice } from "../../baseApi";

export const extendedAdminUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAdminUser: builder.mutation({
      query: (body) => ({
        url: "/admin-user/register-admin",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["AdminUser"],
    }),

    verifyAdminUser: builder.mutation({
      query: (body) => ({
        url: "/auth/verify-admin",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["AdminUser"],
    }),

    getAdminUsers: builder.query({
      query: () => ({
        url: "/admin-user/admin-users",
      }),
      invalidatesTags: ["AdminUser"],
    }),

    disableAdmin: builder.mutation({
      query: (body) => ({
        url: "/admin-user/disable-admin",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["AdminUser"],
    }),
  }),
});

export const {
  useGetAdminUsersQuery,
  useAddAdminUserMutation,
  useDisableAdminMutation,
  useVerifyAdminUserMutation,
} = extendedAdminUserApi;
