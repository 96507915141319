import { apiSlice } from "../../baseApi";

export const extendedConnectionTypeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addConnectionType: builder.mutation({
      query: (body) => ({
        url: "/connection-type",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ConnectionType"],
    }),

    updateConnectionTypeStatus: builder.mutation({
      query: (body) => ({
        url: "/connection-type/update-status",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ConnectionType"],
    }),

    getConnectionType: builder.query({
      query: () => ({
        url: "/connection-type",
      }),
      invalidatesTags: ["ConnectionType"],
    }),

    updateConnectionType: builder.mutation({
      query: (data) => ({
        url: "/connection-type",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["ConnectionType"],
    }),
    getConnectionTypeById: builder.query({
      query: (id) => `/connection-type/${id}`,
      invalidatesTags: ["ConnectionType"],
    }),

    deleteConnectionType: builder.mutation({
      query: (id) => ({
        url: `/connection-type/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ConnectionType"],
    }),
  }),
});

export const {
  useAddConnectionTypeMutation,
  useDeleteConnectionTypeMutation,
  useGetConnectionTypeByIdQuery,
  useGetConnectionTypeQuery,
  useUpdateConnectionTypeMutation,
  useUpdateConnectionTypeStatusMutation,
} = extendedConnectionTypeApi;
