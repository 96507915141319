import AuthWrapper from "../../../components/auth/AuthWrapper";
import LoginForm from "../loginForm/loginForm";

const Login = () => {
	return (
		<AuthWrapper>
			<div className="row justify-content-center">
				<div className="col-md-8 col-lg-6 col-xl-5">
					<LoginForm />
				</div>
			</div>
		</AuthWrapper>
	);
};

export default Login;
