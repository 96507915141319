import { apiSlice } from "../../baseApi";

export const extendedNotificationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotification: builder.query({
      query: () => ({
        url: "/notification",
      }),
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const { useGetNotificationQuery } = extendedNotificationApi;
