import { apiSlice } from "../../baseApi";

export const extendedVehicleTypeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addVehicleType: builder.mutation({
      query: (body) => ({
        url: "/vehicle-type",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["VehicleType"],
    }),

    getVehicleType: builder.query({
      query: () => ({
        url: "/vehicle-type",
      }),
      providesTags: (result, query, args) => [{ type: "VehicleType" }],
    }),

    updateVehicleType: builder.mutation({
      query: (data) => ({
        url: "/vehicle-type",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["VehicleType"],
    }),
    getVehicleTypeById: builder.query({
      query: (id) => `/vehicle-type/${id}`,
      providesTags: ["VehicleType"],
    }),

    deleteVehicleType: builder.mutation({
      query: (id) => ({
        url: `/vehicle-type/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VehicleType"],
    }),
  }),
});

export const {
  useAddVehicleTypeMutation,
  useDeleteVehicleTypeMutation,
  useGetVehicleTypeByIdQuery,
  useGetVehicleTypeQuery,
  useUpdateVehicleTypeMutation,
} = extendedVehicleTypeApi;
