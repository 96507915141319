// import { userApi } from "./userApi";
import { apiSlice } from "../../baseApi";

export const extendedAttendantApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAttendant: builder.mutation({
      query: (body) => ({
        url: "/attendants/add-attendant",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Attendant"],
    }),

    // getAllUsers: builder.query({
    //   query: () => ({
    //     url: "/user-activity",
    //   }),
    //   providesTags: (result, query, args) => [{ type: "User" }],
    // }),
  }),
});

export const { useAddAttendantMutation } = extendedAttendantApi;
