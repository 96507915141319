import React, { useEffect } from "react";

function LogoutButton() {
  const handleLogout = () => {
    localStorage.removeItem("admin-token");
    window.history.replaceState(null, "", "/login");
    window.location.replace("/login");
  };

  useEffect(() => {
    handleLogout();
  }, []);
  return;
}

export default LogoutButton;
