import { apiSlice } from "../../baseApi";

export const extendedTermsAndConditonsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addTermsAndConditions: builder.mutation({
      query: (body) => ({
        url: "/terms-conditions",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["TermsAndConditions"],
    }),

    getTermsAndConditions: builder.query({
      query: () => ({
        url: "/terms-conditions",
      }),
      providesTags: (result, query, args) => [{ type: "TermsAndConditions" }],
    }),

    updateTermsAndConditions: builder.mutation({
      query: (id, data) => ({
        url: `/terms-conditions/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["TermsAndConditions"],
    }),
    getChargingTypeById: builder.query({
      query: (id) => `/terms-conditions/${id}`,
      providesTags: ["TermsAndConditions"],
    }),

    deleteTermsAndConditions: builder.mutation({
      query: (id) => ({
        url: `/terms-conditions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TermsAndConditions"],
    }),
  }),
});

export const {
  useAddTermsAndConditionsMutation,
  useDeleteTermsAndConditionsMutation,
  useGetChargingTypeByIdQuery,
  useGetTermsAndConditionsQuery,
  useUpdateTermsAndConditionsMutation,
} = extendedTermsAndConditonsAPI;
