import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const AdminDashboard = ({
  isOpenPages,
  isOpenBooking,
  isOpenSettings,
  handleMouseEnterPages,
  handleMouseLeavePages,
  handleMouseEnterBooking,
  handleMouseLeaveBooking,
  handleMouseEnterSettings,
  handleMouseLeaveSettings,
}) => {
  return (
    <>
      <ul className="navbar-nav" id="navbar-nav">
        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarDashboard"
          >
            <i class="lab la-dashcube"></i>{" "}
            <span data-key="t-authentication">Dashboard</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard/users"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarProducts"
          >
            <i class="las la-boxes"></i>{" "}
            <span data-key="t-authentication">Users</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard/attendants"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarProducts"
          >
            <i class="las la-boxes"></i>{" "}
            <span data-key="t-authentication">Attendants</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard/charging-point-admin"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarProducts"
          >
            <i class="las la-boxes"></i>{" "}
            <span data-key="t-authentication">Station Admins</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard/charging-point"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarProducts"
          >
            <i class="las la-boxes"></i>{" "}
            <span data-key="t-authentication">Charging Points</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard/bookings"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarProducts"
          >
            <i class="las la-boxes"></i>{" "}
            <span data-key="t-authentication">Bookings</span>
          </Link>
        </li>
        {/* start */}
        <li
          className="nav-item dropdown"
          onMouseEnter={handleMouseEnterPages}
          onMouseLeave={handleMouseLeavePages}
        >
          <Link
            className="nav-link menu-link dropdown-toggle"
            aria-expanded={isOpenPages ? "true" : "false"}
            aria-controls="submenu-pages"
            to="/dashboard/users"
          >
            <i className="las la-shopping-bag"></i>
            <span data-key="t-authentication">Pages</span>
          </Link>
          <ul
            className={`dropdown-menu ${isOpenPages ? "show" : ""}`}
            id="submenu-pages"
            onMouseEnter={handleMouseEnterPages}
            onMouseLeave={handleMouseLeavePages}
          >
            <li>
              <Link
                to="/dashboard/terms-and-conditions"
                className="dropdown-item"
              >
                <i className="las la-file-contract"></i> Terms and Conditions
              </Link>
            </li>
            <li>
              <Link to="/dashboard/privacy-policy" className="dropdown-item">
                <i className="las la-shield-alt"></i> Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/dashboard/faq" className="dropdown-item">
                <i className="las la-question-circle"></i> Frequently Asked
                Questions
              </Link>
            </li>
            {/* Add more subcategories as needed */}
          </ul>
        </li>
        {/* end */}

        {/* start */}
        <li
          className="nav-item dropdown"
          onMouseEnter={handleMouseEnterBooking}
          onMouseLeave={handleMouseLeaveBooking}
        >
          <Link
            className="nav-link menu-link dropdown-toggle"
            aria-expanded={isOpenBooking ? "true" : "false"}
            aria-controls="submenu-booking"
            to="/dashboard/booking-slot"
          >
            <i className="las la-calendar-alt"></i>{" "}
            <span data-key="t-authentication">Booking Slot</span>
          </Link>
          <ul
            className={`dropdown-menu ${isOpenBooking ? "show" : ""}`}
            id="submenu-booking"
            onMouseEnter={handleMouseEnterBooking}
            onMouseLeave={handleMouseLeaveBooking}
          >
            <li>
              <Link to="/dashboard/vehicle-model" className="dropdown-item">
                <i className="las la-car"></i> Vehicle Model
              </Link>
            </li>
            <li>
              <Link to="/dashboard/connection-type" className="dropdown-item">
                <i className="las la-plug"></i> Connection Type
              </Link>
            </li>
            <li>
              <Link to="/dashboard/vehicle-type" className="dropdown-item">
                <i className="las la-truck"></i> Vehicle Type
              </Link>
            </li>
            <li>
              <Link to="/dashboard/vehicle-type" className="dropdown-item">
                <i className="las la-truck"></i> Amenities
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarOrders"
          >
            <i class="las la-dolly"></i>{" "}
            <span data-key="t-authentication">Help</span>
          </Link>
        </li>

        {/* start */}
        <li
          className="nav-item dropdown"
          onMouseEnter={handleMouseEnterPages}
          onMouseLeave={handleMouseLeavePages}
        >
          <Link
            className="nav-link menu-link dropdown-toggle"
            aria-expanded={isOpenPages ? "true" : "false"}
            aria-controls="submenu-pages"
            to="/dashboard/users"
          >
            <i className="las la-shopping-bag"></i>
            <span data-key="t-authentication">Settings</span>
          </Link>
          <ul
            className={`dropdown-menu ${isOpenSettings ? "show" : ""}`}
            id="submenu-pages"
            onMouseEnter={handleMouseEnterSettings}
            onMouseLeave={handleMouseLeaveSettings}
          >
            <li>
              <Link to="/dashboard/privacy-policy" className="dropdown-item">
                <i className="las la-shield-alt"></i> Change Password
              </Link>
            </li>

            {/* Add more subcategories as needed */}
          </ul>
        </li>
        {/* end */}

        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/dashboard/notifications"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarStaff"
          >
            <i class="las la-user-cog"></i>{" "}
            <span data-key="t-authentication">Notifications</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link menu-link"
            to="/logout"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarLogout"
          >
            <i class="las la-sign-out-alt"></i>{" "}
            <span data-key="t-authentication">Logout</span>
          </Link>
        </li>
      </ul>
    </>
  );
};
