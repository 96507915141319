import React, { Fragment, useState, useEffect, useRef } from "react";
import { FaAlignLeft } from "react-icons/fa";
import NavbarBrandMenu from "./components/menuBrand/navbarBrandMenu";
import NavbarScrollbar from "./components/menuScroll/navbarMenuScrollbar";

export default function NavbarMenu() {
  const [openSideBar, setOpenSideBar] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSideBar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <button
        onClick={() => setOpenSideBar(!openSideBar)}
        className="btn btn__user btn-icon btn-topbar btn-ghost-secondary rounded-circle absolute top-2 left-2 z-50"
        style={{
          zIndex: 9999,
          color: "#571ab7",
          height: "60px",
          width: "60px",
          padding: "10px",
        }}
      >
        {openSideBar ? (
          <i className="bx bx-x" style={{ fontSize: "3px" }}></i> // Close icon
        ) : (
          <i className="bx bx-menu" style={{ fontSize: "35px" }}></i> // Menu icon
        )}
      </button>

      <div
        ref={sidebarRef}
        className={`app-menu navbar-menu ${
          !openSideBar ? "sidebar_patch" : ""
        }`}
      >
        <NavbarBrandMenu />
        <NavbarScrollbar />
      </div>

      <div className="vertical-overlay"></div>
    </Fragment>
  );
}
