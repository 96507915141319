import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../../utils/colors";
import { Col, Modal, Button, Row, Form } from "react-bootstrap";
import { useGetConnectionTypeQuery } from "../../../../../features/api/connection-type/connectionTypeAPI";
import { useAddChargingTypeToChargingPointMutation } from "../../../../../features/api/charging-point/chargingPointAPI";
import { useGetChargingPointByAttendantQuery } from "../../../../../features/api/charging-point/chargingPointAPI";
import { useGetConnectionTypeByChargingPointQuery } from "../../../../../features/api/charging-point/chargingPointAPI";
import { useUpdateConnectionTypeStatusMutation } from "../../../../../features/api/connection-type/connectionTypeAPI";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

function ConnectorContent() {
  const [showOOS, setshowOOS] = useState(false);
  const [showInUse, setshowInUse] = useState(false);
  const [showAvailable, setshowAvailable] = useState(false);
  const [showAddConnector, setShowAddConnector] = useState(false);
  const [chargingPoint, setSelectedChargingPoint] = useState();
  const [selectedChargingTypes, setSelectedChargingTypes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const { data: chargingTypeData, refetch: refetchConnectionTypes } = useGetConnectionTypeQuery();
  const { data: connectionTypeByChargingPoint, refetch: refetchConnectionTypeByChargingPoint } = useGetConnectionTypeByChargingPointQuery();
  const [addToConnectorList] = useAddChargingTypeToChargingPointMutation();
  const [changeConnectorStatus] = useUpdateConnectionTypeStatusMutation();

  const handleCloseOOS = () => setshowOOS(false);
  const handleOpenOOS = () => setshowOOS(true);
  const handleCloseInUse = () => setshowInUse(false);
  const handleOpenInUse = () => setshowInUse(true);
  const handleCloseAvailable = () => setshowAvailable(false);
  const handleOpenAvailable = () => setshowAvailable(true);
  const handleCloseAddConnector = () => setShowAddConnector(false);
  const handleOpenAddConnector = () => setShowAddConnector(true);

  const handleCheckboxChange = (id) => {
    const isSelected = selectedChargingTypes.includes(id);
    if (isSelected) {
      setSelectedChargingTypes(selectedChargingTypes.filter((typeId) => typeId !== id));
    } else {
      setSelectedChargingTypes([...selectedChargingTypes, id]);
    }
  };

  const handleChangeStatus = async (newStatus) => {
    setShowSpinner(true);
    try {
      const data = await changeConnectorStatus({
        connectionTypeId: chargingPoint,
        newStatus,
      });

      if (data?.data?.success) {
        toast.success("Connector status updated successfully");
        refetchConnectionTypes();
        refetchConnectionTypeByChargingPoint();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setShowSpinner(false);
      setSelectedChargingPoint(null);
      handleCloseOOS();
      handleCloseAvailable();
      handleCloseInUse();
    }
  };

  const handleAddConnector = async () => {
    setShowSpinner(true);
    try {
      const data = await addToConnectorList({
        connectionType: selectedChargingTypes,
      });

      if (data?.data?.success === true) {
        toast.success("Connectors added successfully");
        refetchConnectionTypes();
        handleCloseAddConnector();
      } else {
        toast.info("Something went wrong");
      }
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="">
              <div className="d-flex justify-content-between" style={{}}>
                <h4 className="card-title mb-3 " style={{ fontSize: "20px" }}>
                  {" "}
                  Connectors{" "}
                </h4>
                <button
                  type="button"
                  className="btn btn-soft-success"
                  onClick={handleOpenAddConnector}
                >
                  <i className="ri-add-circle-line align-middle me-1"></i>
                  Add Connectors
                </button>
              </div>

              <div className="row">
                {connectionTypeByChargingPoint &&
                connectionTypeByChargingPoint.data &&
                connectionTypeByChargingPoint.data.length > 0 ? (
                  connectionTypeByChargingPoint.data.map((connector) => (
                    <div className="col-xl-3 col-md-6" key={connector._id}>
                      <div
                        className="card card-animate"
                        style={{
                          borderColor:
                            connector?.status === "available"
                              ? "#a2faba"
                              : connector?.status === "outOfService"
                              ? "#f5afab"
                              : connector?.status === "inUse"
                              ? "#f5daab"
                              : "defaultColor",
                          borderStyle: "solid", 
                          borderWidth: "2px", 
                        }}
                      >
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                {connector?.name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                <span
                                  className="counter-value"
                                  data-target="559.25"
                                >
                                  {connector?.status === "available"
                                    ? "AVAILABLE"
                                    : connector?.status === "outOfService"
                                    ? "OUT OF SERVICE"
                                    : connector?.status === "inUse"
                                    ? "IN USE"
                                    : "CURRENTLY IN USE"}
                                </span>
                              </h4>
                              <div className="d-flex gap-3">
                                <button
                                  type="button"
                                  className="btn btn-soft-success"
                                  onClick={() => {
                                    handleOpenAvailable();
                                    setSelectedChargingPoint(connector?._id);
                                  }}
                                >
                                  <i className="ri-add-circle-line align-middle me-1"></i>
                                  Available
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-soft-warning"
                                  onClick={() => {
                                    handleOpenInUse();
                                    setSelectedChargingPoint(connector?._id);
                                  }}
                                >
                                  <i className="ri-add-circle-line align-middle me-1"></i>
                                  In Use
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-danger"
                                  onClick={() => {
                                    handleOpenOOS();
                                    setSelectedChargingPoint(connector?._id);
                                  }}
                                >
                                  <i className="ri-add-circle-line align-middle me-1"></i>
                                  OOS
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No connection types available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showOOS} onHide={handleCloseOOS}>
        <Modal.Header closeButton>
          <Modal.Title>Out Of Service</Modal.Title>
        </Modal.Header>
        <form action="" onSubmit="">
          <Modal.Body>
            <p>
              You are about to toggle a connector to{" "}
              <strong>{"Out of Service"}</strong>, doing this will stop other
              users from accessing this charging point from their end. Do you
              want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button className="btn btn-primary" onClick={handleCloseOOS}>
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button
                className="btn btn-success"
                type="submit"
                onClick={() => handleChangeStatus("outOfService")}
              >
                Yes, Move to OOS
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showAvailable} onHide={handleCloseAvailable}>
        <Modal.Header closeButton>
          <Modal.Title>Available</Modal.Title>
        </Modal.Header>
        <form action="" onSubmit="">
          <Modal.Body>
            <p>
              You are about to toggle a connector to{" "}
              <strong>{"Available"}</strong>, doing this will allow other users
              to access this charging point from their end. Do you want to
              continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button
                className="btn btn-primary"
                onClick={handleCloseAvailable}
              >
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button
                className="btn btn-success"
                type="submit"
                onClick={() => handleChangeStatus("available")}
              >
                Yes, Make Available
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showInUse} onHide={handleCloseInUse}>
        <Modal.Header closeButton>
          <Modal.Title>Currently In Use</Modal.Title>
        </Modal.Header>
        <form action="" onSubmit="">
          <Modal.Body>
            <p>
              You are about to toggle a connector to <strong>{"In Use"}</strong>
              , doing this will alert other users that this charging point is
              currently in use. Do you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!showSpinner && (
              <Button className="btn btn-primary" onClick={handleCloseInUse}>
                No, Cancel
              </Button>
            )}
            {!showSpinner && (
              <Button
                className="btn btn-success"
                type="submit"
                onClick={() => handleChangeStatus("inUse")}
              >
                Yes, In Use
              </Button>
            )}
            {showSpinner && (
              <center>
                <RotatingLines
                  width="30"
                  strokeColor={PRIMARY_COLOR}
                  strokeWidth="3"
                />
              </center>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showAddConnector} onHide={handleCloseAddConnector}>
        <Modal.Header closeButton>
          <Modal.Title>Add Connector</Modal.Title>
        </Modal.Header>
        <Form.Group>
          <div style={{ padding: "30px" }}>
            <>
              {" "}
              <Form>
                {chargingTypeData &&
                  chargingTypeData.connectionTypes.map(
                    (connectionType, index) => (
                      <Form.Check
                        key={index}
                        type="switch"
                        id={`custom-switch-${index}`}
                        label={connectionType.name}
                        style={{ zoom: 1.3 }}
                        onChange={() =>
                          handleCheckboxChange(connectionType._id)
                        }
                      />
                    )
                  )}
                {!chargingTypeData && (
                  <center>
                    <RotatingLines
                      width="30"
                      strokeColor={PRIMARY_COLOR}
                      strokeWidth="3"
                    />
                  </center>
                )}
              </Form>
            </>
          </div>
        </Form.Group>
        <Modal.Footer>
          {!showSpinner && (
            <Button
              type="submit"
              id="add_product"
              className="btn btn-primary"
              onClick={handleAddConnector}
            >
              Add Connector
            </Button>
          )}
          {showSpinner && (
            <center>
              <RotatingLines
                width="30"
                strokeColor={PRIMARY_COLOR}
                strokeWidth="3"
              />
            </center>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConnectorContent;
