import React from "react";
import { VolumeLoader } from "react-loaders-kit";

const Loader = () => {
	const loaderProps = {
		loading: true,
		size: 35,
		duration: 0.6,
		color: ['#5e22f0', '#ffffff']
	};
	return (
		<div className="loader">
			<VolumeLoader {...loaderProps} />
		</div>
	);
};

export default Loader;
