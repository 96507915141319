import React from "react";

const TextArea = ({ name, label, error, placeholder, textarea, ...rest }) => {
  const inputProps = {
    id: name,
    name: name,
    ...rest,
    placeholder: placeholder ? placeholder : "",
    className: "form-control",
  };

  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      {textarea ? <textarea {...inputProps} /> : <input {...inputProps} />}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default TextArea;
