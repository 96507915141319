import { apiSlice } from "../../baseApi";

export const extendedFAQApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addFAQ: builder.mutation({
      query: (body) => ({
        url: "/faq",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FAQ"],
    }),

    getFAQ: builder.query({
      query: () => ({
        url: "/faq",
      }),
      providesTags: (result, query, args) => [{ type: "FAQ" }],
    }),

    updateFAQ: builder.mutation({
      query: ({ id, data }) => ({
        url: `/faq/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["FAQ"],
    }),
    getFAQById: builder.query({
      query: (id) => `/faq/${id}`,
      providesTags: ["FAQ"],
    }),

    deleteFAQ: builder.mutation({
      query: (id) => ({
        url: `/faq/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FAQ"],
    }),
  }),
});

export const {
  useAddFAQMutation,
  useDeleteFAQMutation,
  useGetFAQByIdQuery,
  useGetFAQQuery,
  useUpdateFAQMutation,
} = extendedFAQApi;
