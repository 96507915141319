import React from "react";

const Input = ({ name, label, error, placeholder, ...rest }) => {
	return (
		<div className="mb-3">
			<label htmlFor={name} className="form-label">
				{label}
			</label>
			<input
				id={name}
				name={name}
				{...rest}
				placeholder={placeholder ? placeholder : ""}
				className="form-control"
			/>
			{error && <div className="alert alert-danger">{error}</div>}
		</div>
	);
};

export default Input;
