import { apiSlice } from "../../baseApi";

export const extendedBookingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addBooking: builder.mutation({
      query: (body) => ({
        url: "/bookings",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bookings"],
    }),

    getBooking: builder.query({
      query: () => ({
        url: "/bookings",
      }),
      invalidatesTags: ["Bookings"],
      providesTags: (result, query, args) => [{ type: "User" }],
    }),

    updateBooking: builder.mutation({
      query: (data) => ({
        url: "/bookings",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Bookings"],
    }),
    getBookingById: builder.query({
      query: (id) => `/bookings/${id}`,
      invalidatesTags: ["Bookings"],
      providesTags: ["Booking"],
    }),
    getBookingByChargingPoint: builder.query({
      query: (id) => `/bookings/bookings-by-charging-point`,
      invalidatesTags: ["Bookings"],
      providesTags: ["Booking"],
    }),
    getTopChargingPoint: builder.query({
      query: (id) => `/bookings/top-charging-points`,
      invalidatesTags: ["Bookings"],
      providesTags: ["Booking"],
    }),

    deleteBooking: builder.mutation({
      query: (id) => ({
        url: `/bookings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Bookings"],
    }),
  }),
});

export const {
  useAddBookingMutation,
  useDeleteBookingMutation,
  useGetBookingByIdQuery,
  useGetBookingQuery,
  useGetBookingByChargingPointQuery,
  useGetTopChargingPointQuery,
} = extendedBookingApi;
