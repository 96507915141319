import React, { Fragment } from "react";

export default function SignInButton({ onDisabled }) {
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          // value=""
          id="auth-remember-check"
        />
        <label className="form-check-label" htmlFor="auth-remember-check">
          Remember me
        </label>
      </div>

      <div className="mt-4">
        <button
          disabled={onDisabled}
          className="btn btn-success w-100"
          type="submit"
          style={{ backgroundColor: "#571AB7" }}
        >
          Sign In
        </button>
      </div>
    </>
  );
}
