import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../../assets/images/cb-icon-4.png";
import logoWhite from "../../../../assets/images/cb-logo-2.png";
import logoDark from "../../../../assets/images/cb-logo-4.png";
import EV_Logo from "../../../../assets/images/ConnectVolt-White.png";
import ConnectVolt_Logo_full from "../../../../assets/images/ConnectVolt_Horizontal.png";

const NavbarBrandMenu = () => {
  return (
    <div className="navbar-brand-box">
      <Link to="/dashboard" className="logo logo-dark">
        <span className="logo-sm">
          <img src={EV_Logo} alt="" height="22" />
        </span>
        <span className="logo-lg">
          <img src={logoDark} alt="" height="17" />
        </span>
      </Link>

      <Link to="/dashboard" className="logo logo-light">
        <span className="logo-sm">
          <img src={EV_Logo} alt="" height="22" />
        </span>
        <span className="logo-lg">
          <img src={ConnectVolt_Logo_full} alt="" height="60" />
        </span>
      </Link>
      <button
        type="button"
        className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
        id="vertical-hover"
      >
        <i className="ri-record-circle-line"></i>
      </button>
    </div>
  );
};

export default NavbarBrandMenu;
