import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../../../../utils/colors";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FAQData from "./faqData";
import { useGetFAQQuery } from "../../../../../features/api/faq/faqAPI";

function FAQContent() {
  const [params, setParams] = useSearchParams();
  const [data, setData] = useState();
  const [metadata, setMetaData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { data: faqData, isLoading: faqLoading } = useGetFAQQuery();

  useEffect(() => {
    if (faqData) {
      setData(faqData);
      console.log("vehicle type", faqData);
      setIsLoading(false);
    }
  }, [faqData]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title mb-0">Frequently Asked Questions</h4>
              </div>

              <div className="card-body">
                {!faqLoading && (
                  <FAQData
                    data={data}
                    metadata={metadata}
                    currentPage={params.get("page") ? params.get("page") : 1}
                  />
                )}
                {faqLoading && (
                  <center>
                    <RotatingLines
                      width="30"
                      strokeColor={PRIMARY_COLOR}
                      strokeWidth="3"
                    />
                  </center>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQContent;
