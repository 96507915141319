import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetBookingQuery,
  useGetTopChargingPointQuery,
} from "../../features/api/bookings/bookingSlotAPI";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const SuperAdminDashboardContent = () => {
  const { data: bookingList, loading: bookingListLoading } =
    useGetBookingQuery();

  const { data: topChargingPoint, loading: topChargingPointLoading } =
    useGetTopChargingPointQuery();

  console.log("top charing po", topChargingPoint);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate total pages
  const totalPages = bookingList
    ? Math.ceil(bookingList.length / itemsPerPage)
    : 1;

  // Get current items
  const currentItems = bookingList
    ? bookingList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    : [];

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const generatePDFReport = () => {
    const input = document.getElementById("bookingsTable");

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("bookings_report.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Users
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      0
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Charging Stations
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="36894">
                      0
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Bookings
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="183.35">
                      0
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Admin Users
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="165.89">
                      0
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Attendants
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      0
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Charging Stations
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="36894">
                      0
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Bookings
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="183.35">
                      0
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Users
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="165.89">
                      0
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* recent bookings */}

      <div className="col-xl-12">
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Recent Bookings</h4>
            <div className="flex-shrink-0" onClick={generatePDFReport}>
              <button type="button" className="btn btn-soft-info btn-sm">
                <i className="ri-file-list-3-line align-middle"></i> Generate
                Report
              </button>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive table-card">
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Charging Point</th>
                    <th scope="col">Connector Point</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Charge Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems &&
                    currentItems?.length > 0 &&
                    currentItems?.map((booking, index) => (
                      <tr key={index}>
                        <td>
                          <a
                            href="apps-ecommerce-order-details.html"
                            className="fw-medium link-primary"
                          >
                            {booking?.booking_id}
                          </a>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-2">
                              <img
                                src="assets/images/users/avatar-1.jpg"
                                alt=""
                                className="avatar-xs rounded-circle"
                              />
                            </div>
                            <div className="flex-grow-1">
                              {booking?.user?.frist_name +
                                " " +
                                booking?.user?.last_name}
                            </div>
                          </div>
                        </td>
                        <td>{booking?.charging_point?.name}</td>
                        <td>{booking?.charging_point?.name}</td>

                        <td>
                          <span className="text-success">
                            {booking?.price?.currency +
                              " " +
                              booking?.price?.value}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              booking?.payment_status === "successful"
                                ? "badge-soft-success"
                                : booking?.payment_status === "declined"
                                ? "badge-soft-danger"
                                : "badge-soft-warning"
                            }`}
                          >
                            {booking?.payment_status}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              booking?.charge_status === "charged"
                                ? "badge-soft-success"
                                : booking?.charge_status === "charging"
                                ? "badge-soft-danger"
                                : "badge-soft-warning"
                            }`}
                          >
                            {booking?.charge_status}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <div className="edit">
                              <Link className="btn btn-sm btn-success edit-item-btn">
                                View
                              </Link>
                            </div>
                            {/* <div className="remove">
                              <button
                                className="btn btn-sm btn-danger remove-item-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteRecordModal"
                              >
                                Delete
                              </button>
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* Pagination controls */}
            <div className="d-flex justify-content-between align-items-center mt-4">
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <div>
                <button
                  className="btn btn-primary btn-sm me-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-xl-6">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">
                Best Selling Products
              </h4>
              <div className="flex-shrink-0">
                <div className="dropdown card-header-dropdown">
                  <a
                    className="text-reset dropdown-btn"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="fw-semibold text-uppercase fs-13">
                      Sort by:
                    </span>
                    <span className="text-muted">
                      Today
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" href="#">
                      Today
                    </a>
                    <a className="dropdown-item" href="#">
                      Yesterday
                    </a>
                    <a className="dropdown-item" href="#">
                      Last 7 Days
                    </a>
                    <a className="dropdown-item" href="#">
                      Last 30 Days
                    </a>
                    <a className="dropdown-item" href="#">
                      This Month
                    </a>
                    <a className="dropdown-item" href="#">
                      Last Month
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm bg-light rounded p-1 me-2">
                            <img
                              src="assets/images/products/img-1.png"
                              alt=""
                              className="img-fluid d-block"
                            />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Branded T-Shirts
                              </a>
                            </h5>
                            <span className="text-muted">24 Apr 2021</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$29.00</h5>
                        <span className="text-muted">Price</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">62</h5>
                        <span className="text-muted">Orders</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">510</h5>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$1,798</h5>
                        <span className="text-muted">Amount</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm bg-light rounded p-1 me-2">
                            <img
                              src="assets/images/products/img-2.png"
                              alt=""
                              className="img-fluid d-block"
                            />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Bentwood Chair
                              </a>
                            </h5>
                            <span className="text-muted">19 Mar 2021</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$85.20</h5>
                        <span className="text-muted">Price</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">35</h5>
                        <span className="text-muted">Orders</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">
                          <span className="badge badge-soft-danger">
                            Out of stock
                          </span>{" "}
                        </h5>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$2982</h5>
                        <span className="text-muted">Amount</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm bg-light rounded p-1 me-2">
                            <img
                              src="assets/images/products/img-3.png"
                              alt=""
                              className="img-fluid d-block"
                            />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Borosil Paper Cup
                              </a>
                            </h5>
                            <span className="text-muted">01 Mar 2021</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$14.00</h5>
                        <span className="text-muted">Price</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">80</h5>
                        <span className="text-muted">Orders</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">749</h5>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$1120</h5>
                        <span className="text-muted">Amount</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm bg-light rounded p-1 me-2">
                            <img
                              src="assets/images/products/img-4.png"
                              alt=""
                              className="img-fluid d-block"
                            />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                One Seater Sofa
                              </a>
                            </h5>
                            <span className="text-muted">11 Feb 2021</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$127.50</h5>
                        <span className="text-muted">Price</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">56</h5>
                        <span className="text-muted">Orders</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">
                          <span className="badge badge-soft-danger">
                            Out of stock
                          </span>
                        </h5>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$7140</h5>
                        <span className="text-muted">Amount</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm bg-light rounded p-1 me-2">
                            <img
                              src="assets/images/products/img-5.png"
                              alt=""
                              className="img-fluid d-block"
                            />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Stillbird Helmet
                              </a>
                            </h5>
                            <span className="text-muted">17 Jan 2021</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$54</h5>
                        <span className="text-muted">Price</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">74</h5>
                        <span className="text-muted">Orders</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">805</h5>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">$3996</h5>
                        <span className="text-muted">Amount</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                <div className="flex-shrink-0">
                  <div className="text-muted">
                    Showing <span className="fw-semibold">5</span> of{" "}
                    <span className="fw-semibold">25</span> Results
                  </div>
                </div>
                <ul className="pagination pagination-separated pagination-sm mb-0">
                  <li className="page-item disabled">
                    <a href="#" className="page-link">
                      ←
                    </a>
                  </li>
                  <li className="page-item">
                    <a href="#" className="page-link">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a href="#" className="page-link">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a href="#" className="page-link">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a href="#" className="page-link">
                      →
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card card-height-100">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">
                Top Charging Stations
              </h4>
              <div className="flex-shrink-0">
                <div className="dropdown card-header-dropdown">
                  <a
                    className="text-reset dropdown-btn"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="text-muted">
                      Report
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" href="#">
                      Download Report
                    </a>
                    <a className="dropdown-item" href="#">
                      Export
                    </a>
                    <a className="dropdown-item" href="#">
                      Import
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive table-card">
                <table className="table table-centered table-hover align-middle table-nowrap mb-0">
                  <tbody>
                    {topChargingPoint &&
                      topChargingPoint?.data(
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 me-2">
                                <img
                                  src="assets/images/companies/img-1.png"
                                  alt=""
                                  className="avatar-sm p-2"
                                />
                              </div>
                              <div>
                                <h5 className="fs-14 my-1 fw-medium">
                                  <a
                                    href="apps-ecommerce-seller-details.html"
                                    className="text-reset"
                                  >
                                    iTest Factory
                                  </a>
                                </h5>
                                <span className="text-muted">Oliver Tyler</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-muted">Bags and Wallets</span>
                          </td>
                          <td>
                            <p className="mb-0">8547</p>
                            <span className="text-muted">Stock</span>
                          </td>
                          <td>
                            <span className="text-muted">$541200</span>
                          </td>
                          <td>
                            <h5 className="fs-14 mb-0">
                              32%
                              <i className="ri-bar-chart-fill text-success fs-16 align-middle ms-2"></i>
                            </h5>
                          </td>
                        </tr>
                      )}
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-2">
                            <img
                              src="assets/images/companies/img-2.png"
                              alt=""
                              className="avatar-sm p-2"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="fs-14 my-1 fw-medium">
                              <a
                                href="apps-ecommerce-seller-details.html"
                                className="text-reset"
                              >
                                Digitech Galaxy
                              </a>
                            </h5>
                            <span className="text-muted">John Roberts</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-muted">Watches</span>
                      </td>
                      <td>
                        <p className="mb-0">895</p>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <span className="text-muted">$75030</span>
                      </td>
                      <td>
                        <h5 className="fs-14 mb-0">
                          79%
                          <i className="ri-bar-chart-fill text-success fs-16 align-middle ms-2"></i>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-2">
                            <img
                              src="assets/images/companies/img-3.png"
                              alt=""
                              className="avatar-sm p-2"
                            />
                          </div>
                          <div className="flex-gow-1">
                            <h5 className="fs-14 my-1 fw-medium">
                              <a
                                href="apps-ecommerce-seller-details.html"
                                className="text-reset"
                              >
                                Nesta Technologies
                              </a>
                            </h5>
                            <span className="text-muted">Harley Fuller</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-muted">Bike Accessories</span>
                      </td>
                      <td>
                        <p className="mb-0">3470</p>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <span className="text-muted">$45600</span>
                      </td>
                      <td>
                        <h5 className="fs-14 mb-0">
                          90%
                          <i className="ri-bar-chart-fill text-success fs-16 align-middle ms-2"></i>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-2">
                            <img
                              src="assets/images/companies/img-8.png"
                              alt=""
                              className="avatar-sm p-2"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="fs-14 my-1 fw-medium">
                              <a
                                href="apps-ecommerce-seller-details.html"
                                className="text-reset"
                              >
                                Zoetic Fashion
                              </a>
                            </h5>
                            <span className="text-muted">James Bowen</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-muted">Clothes</span>
                      </td>
                      <td>
                        <p className="mb-0">5488</p>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <span className="text-muted">$29456</span>
                      </td>
                      <td>
                        <h5 className="fs-14 mb-0">
                          40%
                          <i className="ri-bar-chart-fill text-success fs-16 align-middle ms-2"></i>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-2">
                            <img
                              src="assets/images/companies/img-5.png"
                              alt=""
                              className="avatar-sm p-2"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="fs-14 my-1 fw-medium">
                              <a
                                href="apps-ecommerce-seller-details.html"
                                className="text-reset"
                              >
                                Meta4Systems
                              </a>
                            </h5>
                            <span className="text-muted">Zoe Dennis</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-muted">Furniture</span>
                      </td>
                      <td>
                        <p className="mb-0">4100</p>
                        <span className="text-muted">Stock</span>
                      </td>
                      <td>
                        <span className="text-muted">$11260</span>
                      </td>
                      <td>
                        <h5 className="fs-14 mb-0">
                          57%
                          <i className="ri-bar-chart-fill text-success fs-16 align-middle ms-2"></i>
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                <div className="flex-shrink-0">
                  <div className="text-muted">
                    Showing <span className="fw-semibold">5</span> of{" "}
                    <span className="fw-semibold">25</span> Results
                  </div>
                </div>
                <ul className="pagination pagination-separated pagination-sm mb-0">
                  <li className="page-item disabled">
                    <a href="#" className="page-link">
                      ←
                    </a>
                  </li>
                  <li className="page-item">
                    <a href="#" className="page-link">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a href="#" className="page-link">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a href="#" className="page-link">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a href="#" className="page-link">
                      →
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SuperAdminDashboardContent;
