import { apiSlice } from "../../baseApi";

export const extendedChargingPointApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addChargingPoint: builder.mutation({
      query: (body) => ({
        url: "/charging-point",
        method: "POST",
        body: body,
      }),

      invalidatesTags: ["ChargingPoint"],
    }),

    getChargingPoint: builder.query({
      query: () => ({
        url: "/charging-point",
      }),
      invalidatesTags: ["ChargingPoint"],
      providesTags: (result, query, args) => [{ type: "ChargingPoint" }],
    }),

    updateChargingPoint: builder.mutation({
      query: (data) => ({
        url: "/charging-point",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["ChargingPoint"],
    }),
    getChargingPointById: builder.query({
      query: (id) => `/charging-point/${id}`,
      providesTags: ["ChargingPoint"],
      invalidatesTags: ["ChargingPoint"],
    }),
    getAttendantByChargingPointById: builder.query({
      query: (id) => `/charging-point/attendants-by-charging-point`,
      providesTags: ["ChargingPoint"],
      invalidatesTags: ["ChargingPoint"],
    }),

    deleteChargingPoint: builder.mutation({
      query: (id) => ({
        url: `/charging-point/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ChargingPoint"],
    }),
    approveChargingPoint: builder.mutation({
      query: (id) => ({
        url: `/charging-point/approve/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["ChargingPoint"],
    }),
    addChargingTypeToChargingPoint: builder.mutation({
      query: (body) => ({
        url: `/charging-point/update-connection-type`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ChargingPoint"],
    }),
    getChargingPointByAttendant: builder.query({
      query: () => ({
        url: "/charging-point/charging-point-by-attendant",
      }),
      providesTags: (result, query, args) => [{ type: "ChargingPoint" }],
      invalidatesTags: ["ChargingPoint"],
    }),
    getConnectionTypeByChargingPoint: builder.query({
      query: () => ({
        url: "/charging-point/connection-types",
      }),
      invalidatesTags: ["ChargingPoint"],
      providesTags: (result, query, args) => [{ type: "ChargingPoint" }],
    }),
    addAttendantToChargingPoint: builder.mutation({
      query: (body) => ({
        url: `/charging-point/add-attendants`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ChargingPoint"],
    }),
  }),
});

export const {
  useAddChargingPointMutation,
  useDeleteChargingPointMutation,
  useGetChargingPointByIdQuery,
  useGetChargingPointQuery,
  useUpdateChargingPointMutation,
  useApproveChargingPointMutation,
  useAddChargingTypeToChargingPointMutation,
  useGetChargingPointByAttendantQuery,
  useAddAttendantToChargingPointMutation,
  useGetAttendantByChargingPointByIdQuery,
  useGetConnectionTypeByChargingPointQuery,
} = extendedChargingPointApi;
