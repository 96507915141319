import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import VehicleModelData from "./vehicleModelData";

import { PRIMARY_COLOR } from "../../../../../utils/colors";
import { useGetAllUsersQuery } from "../../../../../features/api/users/user";
import { useGetVehicleModelQuery } from "../../../../../features/api/vehicle-model/vehicleModelAPI";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

function VehicleModelContent() {
  const [params, setParams] = useSearchParams();
  const [users, setUsers] = useState();

  const [metadata, setMetaData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { data: vehicleModelData, isLoading: vehicleModelLoading } =
    useGetVehicleModelQuery();

  console.log("data", vehicleModelData);
  useEffect(() => {
    if (vehicleModelData) {
      setUsers(vehicleModelData);
      console.log(vehicleModelData);
      setIsLoading(false);
    }
  }, [vehicleModelData]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title mb-0">Vehicle Model</h4>
              </div>

              <div className="card-body">
                {!vehicleModelLoading && (
                  <VehicleModelData
                    data={vehicleModelData}
                    metadata={metadata}
                    currentPage={params.get("page") ? params.get("page") : 1}
                  />
                )}
                {vehicleModelLoading && (
                  <center>
                    <RotatingLines
                      width="30"
                      strokeColor={PRIMARY_COLOR}
                      strokeWidth="3"
                    />
                  </center>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleModelContent;
