import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";

import VehicleTypeData from "./vehicleTypeData";
import { PRIMARY_COLOR } from "../../../../../utils/colors";

import { useGetAllUsersQuery } from "../../../../../features/api/users/user";
import { useGetVehicleTypeQuery } from "../../../../../features/api/vehicle-type/vehicleType";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

function VehicleTypeContent() {
  const [params, setParams] = useSearchParams();
  const [users, setUsers] = useState();
  const [metadata, setMetaData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { data: vehicleTypeData, isLoading: vehicleTypesLoading } =
    useGetVehicleTypeQuery();

  useEffect(() => {
    if (vehicleTypeData) {
      setUsers(vehicleTypeData);
      console.log("jj", vehicleTypeData);
      setIsLoading(false);
    }
  }, [vehicleTypeData]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title mb-0">Vehicle Type</h4>
              </div>

              <div className="card-body">
                {!vehicleTypesLoading && (
                  <VehicleTypeData
                    data={vehicleTypeData}
                    metadata={metadata}
                    currentPage={params.get("page") ? params.get("page") : 1}
                  />
                )}
                {vehicleTypesLoading && (
                  <center>
                    <RotatingLines
                      width="30"
                      strokeColor={PRIMARY_COLOR}
                      strokeWidth="3"
                    />
                  </center>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleTypeContent;
